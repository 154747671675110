.disabled {
	// override any existing css rules.
	cursor: not-allowed!important;
	pointer-events: none!important;
}

input[type=text]:disabled,
input[type=email]:disabled,
input[type=password]:disabled,
input[type=text].disabled,
input[type=email].disabled,
input[type=password].disabled {
	background-color: darken($white, 8%);
	color: lighten($gray, 15%);
}

.novalidate {
	background-color: lighten($gray, 71%);
	color: lighten($gray, 61%);
}

.has-error{
	color: $danger;
}

.has-success {
	color: $success;
}

.has-warning {
	color: $warning;
}

.msg {
	position: relative;
	padding: 12px 8px 12px 16px;/*IE8*/
	margin: 8px 0;/*IE8*/
	padding: rem(12px) rem(8px) rem(12px) rem(16px);
	margin: rem(8px) 0;
	border: 0;
	border-left: 12px solid;
	text-align: center;
	border-radius: 4px 2px 2px 4px;/*IE8*/
	border-radius: rem(4px) rem(2px) rem(2px) rem(4px);
	font-size: ($base-font-size / 1.06);
	.msg-close {
		float: right;
		font-size: 20px;/*IE8*/
		font-size: rem(20px);
		line-height: 1;
		cursor: pointer;
	}
	&.error-msg {
		background-color: lighten($danger, 56%) !important;
		border-color: lighten($danger, 32%) !important;
		color: $danger !important;
	}
	&.success-msg {
		background-color: lighten($success, 46%) !important;
		border-color: darken($success, 5%) !important;
		color: $success !important;
	}
	&.warning-msg {
		background-color: darken($warning, 1%) !important;
		border-color: darken($warning, 12%) !important;
		color: scale_color($danger, $lightness: -40%, $saturation: -5%)!important;
	}
	&.generic-msg {
		background-color: lighten($teal, 3%) !important;
		border-color: darken($teal, 55%) !important;
		color: scale_color($teal, $lightness: -80%, $saturation: -10%)!important;
	}
}

.form-ctrl {
	&.has-error {
		border-color: $danger!important;
	}
	&.has-success {
		border-color: $success;
	}
}

form,
.form {
	label > .has-error {
		padding: 1px 4px;/*IE8*/
		margin-left: 4px;/*IE8*/
		padding: rem(1px) rem(4px);
		margin-left: rem(4px);
		background-color: lighten($danger, 56%);
		color: $danger !important;
		font-size: 14px;/*IE8*/
		font-size: rem(13px);
		@include media-mobile {
			font-size: rem(11px);
		}
	}
}
