/* SCRUBSTAR 2018 Redesign */

/*SCRUBSTAR Colors*/
$premiumCollection: #01ACDE;
$fashionCollection: #D90284;
$coreEssentialsCollection: #FF9101;

$light-grey: #ccd0da;
$dark-grey: #a2abb7;
$black: #000000;
$white: #FFFFFF;

body.scrubstar-new {
	.container {
		width: 100%;
	}
	#menu.site-menu #menu_content {
		.label {
			margin-right: initial;
			margin: 0;
			padding: 0;
			a {
				display: block;
				padding: 8px 25px;
				background-color: #A2ABB7;
				color: #ffffff;
				border-left: 1px solid #ffffff;
				&:last-child {
					border-right: 1px solid #ffffff;
				}
			}
			&::after {
				display: none;
			}
		}
	}
	#header_top {
		.site-logo {
			.mod-flexbox & {
				@media (max-width: 500px) {
					width: 50%;
				}
				img {
					margin-top: 15px;
					max-height: 50px;
					@media (max-width: 500px) {
						margin-top: 25px;
						max-width: 120px;
					}
				}
			}
		}
		.site-controls {
			.mod-flexbox & {
				@media (max-width: 500px) {
					width: 50%;
					margin-top: -10px;
					padding-right: 15px;
				}
				align-items: flex-end;
				height: 80px;
				.social-media-icons {
					.footer-heading {
						display: none;
					}
					#social_icons_suite {
						margin-right: 15px;
						.social-icon {
							text-indent: 100%;
							white-space: nowrap;
							background-color: #15bda4;
							display: inline-block;
							overflow: hidden;
							margin: 0 3px 10px;
							padding: 0;
							width: 37px;
							height: 37px;
							position: relative;
							float: none;
							user-select: none;
							border-radius: 50%;
							box-shadow: none;
							transition: all 150ms ease-in-out;
							&:hover {
								box-shadow: 0 2px 4px 2px rgba(0,0,0,0.04), 0 4px 8px 2px rgba(0,0,0,0.14);
							}
							&.pinterest {
								background: url(/meta/pie/images/www.scrubstar.com/pinterest_dark.png) center / cover no-repeat;
							}
							&.facebook {
								background: url(/meta/pie/images/www.scrubstar.com/facebook_dark.png) center / cover no-repeat;
							}
							&.instagram {
								background: url(/meta/pie/images/www.scrubstar.com/instagram_dark.png) center / cover no-repeat;
							}
						}
					}
				}
			}
		}
	}
	#header_bottom {
		#menu {
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: 0;
			#menu_back {
				background-color: $dark-grey;
				border: 0;
			}
			#menu_content {
				margin: 0 0 0 15px;
				.menu {
					.label {
						a {
							&:hover {
								background-color: #ccd0da;
							}
						}
					}
				}
			}
			#search-country-toggle {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-flow: row nowrap;
				flex: 1;
				background-color: $black;
				margin-right: 15px;
				z-index: 1;
				#searchbox {
					margin-top: 0 !important;
					margin-right: 10px;
					margin-left: 20px;
					flex: 1;
					input.search-input {
						padding: 2px 12px 2px 35px;
						border-radius: 6px!important;
						float: left;
						width: 100%;
						max-width: 300px;
						min-width: 180px;
						font-weight: 900;
					}
					.search-button {
						top: 3px;
						right: initial;
						left: 10px;
						height: 20px;
						width: 20px;
						background-size: contain;
					}
				}
				#store_select_dropdown {
					position: relative;
					width: 150px;
					max-height: 39px;
					z-index: 1;
					&.open {
						ul {
							opacity: 1;
							visibility: visible;
							height: auto;
							max-height: 100vh;
							overflow: hidden;
						}
					}
					> .selected {
						display: flex;
						flex-flow: row;
						justify-content: flex-start;
						align-items: center;
						padding: 0px 20px 0px 10px;
						text-decoration: none;
						color: #ffffff;
						line-height: 39px;
						cursor: pointer;
						::after {
							display: block;
							content: 'arrow_drop_down';
							font-family: 'Material Icons';
							color: currentColor;
							position: absolute;
							top: 0;
							right: 20px;
						}
						img {
							margin-right: 15px;
						}
					}
					ul {
						position: absolute;
						top: 0;
						opacity: 0;
						visibility: hidden;
						border-radius: 0 0 3px 3px;
						height: 0;
						max-height: 0;
						box-shadow: 0 2px 2px rgba(0,0,0,0.14), 0 5px 10px 4px rgba(0,0,0,0.2);
						z-index: 10;
						transition: all 150ms ease-in-out;
						li, li a {
							display: flex;
							flex-flow: row;
							justify-content: flex-start;
							align-items: center;
							text-decoration: none;
							line-height: 39px;
							transition: all 150ms ease-in-out;
							&.selected {
								color: #ffffff;
								background-color: #222222;
							}
							&:hover {
								color: #000000;
								background-color: #dddddd;
							}
							img {
								margin-right: 15px;
							}
						}
						li {
							padding: 0px 20px 0px 10px;
							width: 100%;
							color: #000000;
							background-color: #ffffff;
							transition: all 150ms ease-in-out;
							&.first { order: 1; }
							&.second { order: 2; }
						}
					}
				}
			}
			#login { display: none; }
		}
		.mini-links {
			line-height:40px;
			//max-height: 40px;
			margin: 0 15px;
			span.divider {
				color: #ccd0da;
			}
			a {
				display: inline-block;
				margin: 0 10px;
				text-decoration: none;
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
				span {
					text-transform: uppercase;
					&.premium-collection {
						color: $premiumCollection;
					}
					&.fashion-collection {
						color: $fashionCollection;
					}
					&.core-essentials-collection {
						color: $coreEssentialsCollection;
					}
				}
			}
		}
	}
	#slideshow {
		@include media-mobile {
			display: none;
		}
		&.mobile {
			display: none;
			@include media-mobile {
				display: block;
			}
		}
		.slick-list {
			margin: 0 15px;
		}
		.slick-dots {
			position: absolute;
			left: 20px;
			bottom: 5px;
			display: block;
			padding: 0;
			list-style: none;
			text-align: left;
			li {
				margin: 0;
				&.slick-active {
					button {
						&::before {
							opacity: 1;
						}
					}
				}
				button {
					&::before {
						font-size: 11px;
						color: #FFFFFF;
						opacity: 0.45;
					}
				}
			}
		}
	}
	#footer.site-footer {
		padding: 0;
		background-color: transparent;
		border-top: 0;
		#mailing_list_box,
		#mailing_list_submit {
			font-weight: bold;
		}
		.footer-heading {
			font-weight: normal;
			@include media-mobile {
				display: block;
				text-align: center !important;
			}
		}
		.hidden { display: none; }
		.site-footer--top.group {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 20px 0;
			background-color: #A2ABB7;
		}
		.subscribe--input { border-radius: 6px; border: 0; }
		.subscribe--button { border: 0; outline: 0; background: transparent; width: 60px; }
		#footer_social_media {
			width: 60%;
			@include media-mobile {
				width: 100%;
			}
			.footer-heading, #social_icons_suite {
				text-align: right;
				@include media-mobile {
					text-align: center;
				}
			}
			.social-icon {
				border-radius: 50%;
				box-shadow: none;
				transition: all 150ms ease-in-out;
				width: 40px;
				height: 40px;
				margin: 0 3px 0;
				&:hover {
					box-shadow: 0 2px 4px 2px rgba(0,0,0,0.04), 0 4px 8px 2px rgba(0,0,0,0.14);
				}
				&.pinterest {
					background: url(/meta/pie/images/www.scrubstar.com/pinterest_light.png) center / cover no-repeat;
				}
				&.facebook {
					background: url(/meta/pie/images/www.scrubstar.com/facebook_light.png) center / cover no-repeat;
				}
				&.instagram {
					background: url(/meta/pie/images/www.scrubstar.com/instagram_light.png) center / cover no-repeat;
				}
			}
		}
		.site-footer--bottom {
			color: #000;
			a, p, li, span {
				color: #000;
			}
		}
	}
	#close_sidebar {
		display: none;
	}
	#breadcrumbs {
		i {
			margin: 0 5px;
		}
		a {
			color: #222222 !important;
		}
	}
	@media (min-width: 1070px) {
		#header_top {
			.site-logo {
				img {
					margin-left: 15px;
				}
			}
			.social-media-icons {
				margin-right: 15px;
			}
		}
	}
	// MOBILE VIEW
	@media (max-width: 1070px) {
		#mobile_menu { display: none; }
		#menu_toggle {
			display: block;
			float: left;
			position: relative;
			height: 20px;
			width: 30px;
			margin: 30px 15px 0;
			color: #A2ABB7;
			background: none;
			cursor: pointer;
			.bar {
				display: block;
				color: #A2ABB7;
				background-color: currentColor;
				width: 100%;
				height: 3px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			&::before { top: 0; }
			&::after { bottom: 0; }
			&::before, &::after {
				position: absolute;
				display: block;
				content: '';
				height: 3px;
				width: 100%;
				background-color: currentColor;
			}
		}
		#header_bottom.site-header--bottom.group {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-content: flex-start;
			
			position: fixed;
			top: 0;
			left: -310px;
			height: 100%;
			width: 300px;
			overflow-y: auto;
			background-color: #a2abb7;
			z-index: 100;
			box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1), -2px 0 20px 5px rgba(0,0,0,0.2);
			transition: all 150ms ease-in-out;
			&.open {
				left: 0;
			}
			#close_sidebar {
				order: 1;
				display: block;
				width: 100%;
				padding: 5px 10px;
				icon {
					float: right;
					color: #ffffff;
					font-size: 36px;
					cursor: pointer;
				}
			}
			#menu {
				order: 3;
				display: block;
				//flex-flow: column nowrap;
				//margin-top: 130px;
				#menu_back {
					display: none;
				}
				#close_sidebar {
					display: block;
					width: 100%;
					padding: 5px 10px;
					icon {
						float: right;
						color: #ffffff;
						font-size: 36px;
						cursor: pointer;
					}
				}
				#menu_content.mobile-dropdown {
					width: 100%;
					.menu {
						width: 100%;
						.label {
							a {
								border: 0;
								text-decoration: none;
							}
						}
					}
					#mobile_close, #login, .mobile-signin {
						display: none;
					}
				}
				#search-country-toggle {
					flex-flow: column nowrap !important;
					margin: 15px 0;
					background-color: transparent;
					&::before {
						display: block;
						content: '';
						width: 100%;
						height: 1px;
						background-color: #888888;
						margin-bottom: 20px;
					}
					#searchbox {
						margin-left: 20px;
						margin-right: 20px;
						margin-bottom: 20px !important;
					}
					#store_select_dropdown {
						width: calc(100% - 40px);
						margin: 0 20px;
						border-radius: 3px;
						background-color: #CCD0DA;
						> .selected {
							color: #222222;
							span::after {
								content: '';
							}
						}
						.items {
							width: 100%;
							border-radius: 3px;
							li {
								a {
									width: 100%;
								}
							}
						}
					}
				}
			}
			.mini-links {
				order: 2;
				margin: 10px 0;
				/*&::before {
					display: block;
					content: '';
					width: 100%;
					height: 1px;
					background-color: #888888;
					margin-bottom: 20px;
				}*/
				a {
					display: block;
					margin: 0;
					&::before {
						content: '';
						display: block;
						height: 1px;
						width: 100%;
						background-color: #ffffff;
					}
					&:first-child {
						span {
							margin-top: 0;
						}
						&::before {
							display: none;
						}
					}
					span {
						background-color: #CCD0DA;
						display: block;
						margin: 10px 20px;
						padding: 0 10px;
						border-radius: 3px;
						&.premium-collection {
							color: white;
							background-color: $premiumCollection;
						}
						&.fashion-collection {
							color: white;
							background-color: $fashionCollection;
						}
						&.core-essentials-collection {
							color: white;
							background-color: $coreEssentialsCollection;
						}
					}
				}
				.divider {
					display: none;
				}
			}
		}
		#scrubstar_mobile_screen {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 99;
			height: 100%;
			width: 100%;
			background-color: #000000;
			opacity: 0;
			visibility: hidden;
			transition: all 150ms ease-in-out;
			&.open {
				opacity: 0.5;
				visibility: visible;
			}
		}
	}
	@media (max-width: 800px) {
		#header_top {
			width: 100%;
			.site-logo {
				margin-top: 0;
				width: 62%;
			}
			.site-controls {
				width: 38%;
			}
		}
	}
	&.da_site_home {
		.instagram-widget {
			margin: 20px 0 -5px;
			max-width: 100%;
			.scrubstar-instagram-logo {
				margin-left: 15px;
			}
		}
	}
	&.da_category,
	&.da_category_user,
	&.da_category_colors,
	&.da_category_style,
	&.da_search {
		#category_header {
			#category_title {
				.category-heading {
					background-color: transparent;
					font-size: 24px;
					line-height: 24px;
					text-align: left;
				}
			}
			#sorting_and_filtering {
				background-color: #dde0e6;
				border-color: #ccd0da;
				padding: 8px;
				box-shadow: none;
				.filter,
				.sort {
					.selected {
						span {
							color: #222222;
						}
					}
					> .label {
						&::after {
							border-top-color: #000000;
						}
					}
				}
				.sort {
					.sort-options {
						.option {
							&.selected,
							&.selected .label {
								background-color: #222222;
								color: #ffffff;
							}
							.label {
								a {
									&:hover {
										background-color: #222222;
									}
								}
							}
							&:hover,
							&:hover a,
							&:hover .label {
								background-color: #222222;
							}
						}
					}
				}
			}
		}
		#products {
			.social-share {
				display: none;
			}
		}
		.product_container {
			.product_image {
				.brand {
					display: none;
				}
			}
		}
	}
	&.da_style {
		#product_info {
			#description {
				.tech-container {
					.brand_8917 {
						background: url(/meta/pie/0004/0004/2001/logos/scrubstar_color.png) center / contain no-repeat;
					}
				}
			}
			.tech-style-label {
				margin-left: 0;
			}
			.label.uppercase {
				text-transform: capitalize !important;
			}
			#cartform {
				#size_container_noszaction {
					.label {
						a {
							text-decoration: none;
							margin-left: 5px;
						}
					}
				}
				a.add-cart-btn {
					font-family: "Futura Std";
					background-color: #222222;
					&:hover {
						box-shadow: 0 2px 4px 2px rgba(0,0,0,.04), 0 4px 8px 2px rgba(0,0,0,.14);
					}
				}
			}
		}
		#product_images {
			#share.social-share {
				z-index: 90;
				$socialMediaPath: '/meta/pie/images/www.scrubstar.com/product_page/social_media/';
				.st_facebook_large {
					.stButton .stLarge {
						background: url($socialMediaPath + 'facebook.png') center / cover no-repeat !important;
					}
				}
				.st_googleplus_large {
					.stButton .stLarge {
						background: url($socialMediaPath + 'googleplus.png') center / cover no-repeat !important;
					}
				}
				.st_twitter_large {
					.stButton .stLarge {
						background: url($socialMediaPath + 'twitter.png') center / cover no-repeat !important;
					}
				}
				.st_pinterest_large {
					.stButton .stLarge {
						background: url($socialMediaPath + 'pinterest.png') center / cover no-repeat !important;
					}
				}
				.st_instagram_large {
					.stButton .stLarge {
						background: url($socialMediaPath + 'instagram.png') center / cover no-repeat !important;
					}
				}
			}
			.slide-ctrl {
				z-index: 99;
			}
			#complete_outfit {
				.headline {
					font-family: "Futura Std Book";
					font-style: normal;
					color: #a2abb7;
				}
			}
		}
		#color_container .swatch-captions > span {
			text-transform: capitalize;
		}
	}
}