/*
	Experimenting with customizing select inputs.
	The goal here is to make select look consistent on all browsers and OS
	Tested on all popular desktop and mobile browsers
	Fallbacks to default style on older IE browsers (IE8, IE9)

	If any issues with select inputs arise, remove '.modern select' styles.
*/

.modern select,
.modern select.form-ctrl {
   @include reset_appearance;
   background-color: $white!important;
   background-image: url(/meta/pie/0004/0004/0777/icons/select.png)!important;
   background-position: 98% center!important;
   background-repeat: no-repeat!important;
   border-radius: 0!important;
   // text color border color are reset to default unless overwritten
   color: $gray-lighter!important;
   border-color: $gray-light;
   font-size: inherit!important;
   text-overflow: '';
   text-indent: 0.01px;
   overflow: hidden!important;
   padding: 6px!important; /*IE8*/
   padding: rem(6px)!important;
   text-overflow: ellipsis!important;
   white-space: nowrap!important;
   min-width: 100px!important;
   &:focus,
   &:active {
   	border-color: $gray!important;
   	color: $gray!important;
   }
}
/* hide arrow on IE10+ */
.modern ::-ms-expand {
	display: none;
}


/* **************
Forms && Form Panels
**************** */

/* A container that holds login & register forms */
.has-forms {
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1120px;/*IE8*/
    padding: 30px;/*IE8*/
    max-width: rem(1120px);
    padding: rem(30px);
    border: 1px solid $black;
    border-top: 6px solid $black;
    @include media-mobile {
    	border: 0;
    }
}

.form {
	display: block;
	.is-required:before {
	    content: "*";
	    display: block;
	    position: absolute;
	    color: $black;
	    top: -5px;
	    left: -15px;
	    font-weight: $font-weight--bold;
	    font-size: 17px;/*IE8*/
	    font-size: rem(17px);
	}

	.form-group {
		margin: 15px 0;/*IE8*/
		margin: rem(15px) 0;
		position: relative;
	}
	.form-group--inline:after {
		@include clearfix;
	}
	.form-group--inline div {
	    float: left;
	    width: 49%;
	    &:last-child {
	    	margin-left: 2%;
	    }
	}

	.form-label,
	.form-ctrl {
		width: 100%;
		display: block;
		font-size: inherit;
	}
	@include media-mobile {
		.form-label {
			font-size: rem(12px);
		}
	}
	.form-ctrl {
		padding: 0 7px;/*IE8*/
		padding: 0 rem(7px);
		height: 34px;
		border-radius: 3px;
		color: lighten($gray, 16%);
		border: 1px solid scale_color($gray, $lightness: 82%);
		transition: .15s ease-in-out;
		&:focus,
		&:active {
			border-color: $black;
			color: $black;
		}
	}
	input.form-ctrl {
		@include reset_appearance;
	}
	.form-label {
	    font-weight: $font-weight--bold;
	    margin-bottom: 2px;/*IE8*/
	    margin-bottom: rem(2px);
	    &:after {
	    	content: "";
	    }
	}

	.form-link {
		color: lighten($gray, 32%);
        font-weight: normal;
		&:hover,
		&:active,
		&:focus,
		&:visited {
			color: $black;
		}
	}

	.form-chk {
	  display: none;

	}

	.form-chk + label {
	  overflow: hidden;
	  text-indent: -9999px;
	  height: 20px;
	  width: 20px;
	  background-image: url('/meta/pie/0004/0004/0777/icons/checkbox2_unchecked.png');
	  background-size: 20px;
	  background-repeat: no-repeat;
	  cursor: pointer;
 		display: inline-block;
	}

	.form-chk:checked + label {
	  background-image: url('/meta/pie/0004/0004/0777/icons/checkbox2_checked.png');
	}

	.form-chk ~ span {
	  font-size: 14px;/*IE8*/
	  font-size: .9rem;
	  font-weight: $font-weight--bold;
	  vertical-align: top;
	  text-transform: uppercase;
	  margin-left: 8px;
	  //color: #7d7d7d;
	}

	.login-btn,
	.register-btn {
		width: 100%;
		max-width: 240px;/*IE8*/
		max-width: rem(240px);
		font-size: inherit;
        @include media-mobile {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            max-width: 100%;
        }
	}
}

.form--inline {
	display: inline-block;
}
.form--centered {
	padding: 25px;/*IE8*/
	padding: rem(25px);
}
.form--login,
.form--register,
.form--panel {
	width: 48%;
	@include media-mobile {
		width: 100%;
	}
}

.form--login,
.form--register {
	margin-right: 2%;
}
.form--panel-login {
	margin-top: 15%;
}
.form--panel-register {
	margin-top: 5%;
    @include media-mobile {
        margin-top: 10%;
        margin-bottom: 10%;
    }
}

.form--centered,
.panel--centered {
	margin: 6% auto;
	max-width: 500px;/*IE8*/
	max-width: rem(500px);
}
.form-painted,
.panel-painted {
    border: 1px solid $black;
    border-top-width: 6px;
}

.form--headline,
.panel--headline {
    display: block;
    width: 100%;
    padding: 5px 0;
    color: $black;
    text-transform: uppercase;
    font-size: 18px;/*IE8*/
    font-size: rem(18px);
    font-weight: $font-weight--bold;
}

.form--subheading,
.panel--subheading {
	font-size: 15px;/*IE8*/
	font-size: rem(15px);
}

@media only screen and (max-width: 480px) {
	.forgot-password {
		float: none;
		display: inline-block;
		margin-top: rem(8px);
	}
}
