@mixin floatCol($i) {
	float: left;
	width: calc( 100% / ( 12 / #{$i} ) - 30px );
	margin: 0 15px;
	//&:first-child {
	//	margin-left: 0;
	//}
	//&:last-child {
	//	margin-right: 0;
	//}
}

@for $i from 1 through 12 {
	.col-#{$i} {
		@include floatCol($i);
	}
}

@include media-desktop {
	@for $i from 1 through 12 {
		.col-desktop-#{$i} {
			@include floatCol($i);
		}
	}
}

@include media-mobile {
	@for $i from 1 through 12 {
		.col-mobile-#{$i} {
			@include floatCol($i);
		}
	}
}