// background with loader icon
.attach-loader {
	position: relative;
	&:before,
	&:after {
		display: block;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
	}
	&:before {
		content: "";
		background-color: $white; /* Legacy */
		//background-color: rgba($white, .87);
		height: 100%;
		width: 100%;
		z-index: 9998;
	}
	&:after {
		content: url(bg(ajax-loader));
		width: 16px;/*IE8*/
		height: 11px;/*IE8*/
		background-size: 16px 11px;/*IE8*/
		width: rem(16px);
		height: rem(11px);
		background-size: rem(16px) rem(11px);
		margin: auto;
		z-index: 9999;
	}
}

.pp-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white; /*Legacy*/
  background-color: rgba($white, .8);
  z-index: 9998;
	.pp-loader-message {
	  color: $black;
	  position: absolute;
	  display: block;
	  padding-left: 10px;
	  padding-right: 10px;
	  height: 100px;/*IE8*/
	  height: rem(100px);
	  top: 0; left: 0; right: 0; bottom: 0;
	  margin: auto;
	  text-align: center;
	  font-size: 24px;/*IE8*/
	  font-size: rem(24px);
	  font-weight: $font-weight--bold;
	  z-index: 9999;
	}
}
