/* **************
	Header
**************** */

.site-header {
  background-color: $white;
}

.site-logo {
    margin-top: 0;
    width: 40%;
    max-width: 550px;/*IE8*/
    max-height: 80px;/*IE8*/
    max-width: rem(550px);
    max-height: rem(80px);
    text-align: center;
    float: left;
    overflow-y: hidden;

    img {
    	float: left;
    	height: auto;
        max-height: 80px;/*IE8*/
    	max-height: rem(80px);
    }
}

.mod-flexbox {
    .site-controls {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;

        .site-search {
            order: 1;
        }
        .shopping-links {
            order: 0;
        }
    }
}

.site-controls {
	float: right;
	width: 60%;
	text-align: right;

	a {
		color: $gray-lighter;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	.control-panel {
		display: inline-block;
        margin: 12px 5px 0 5px;/*IE8*/
        padding: 0 4px;/*IE8*/
		margin: rem(12px) rem(5px) 0 rem(5px);
		padding: 0 rem(4px);
	}
}

.signin-panel {
	float: right;
	text-align: center;
}

.signin-panel a,
.group-login a {
	font-weight: $font-weight--bold;
	text-decoration: none;
	color: $gray-lighter;
    font-size: 14px;/*IE8*/
	font-size: 0.8rem;
	display: inline;
}

/* #CHECKOUT */

.shopping-links {
    float: right;
    font-size: 14px;/*IE8*/
    line-height: 24px;/*IE8*/
    font-size: .8rem;
    line-height: rem(24px);
	.cart-link {
	    font-weight: $font-weight--regular;
	    color: $gray-lighter;
	    float: left;
	    display: block;
	    text-decoration: none;
	    background-color: transparent;
	    background-image: url(bg(shopping-bag));
	    background-position: right;
        background-size: 18px;/*IE8*/
        min-height: 18px;/*IE8*/
        padding-right: 22px;/*IE8*/
        margin-right: 8px;/*IE8*/
	    background-size: rem(18px);
	    min-height: rem(18px);
	    padding-right: rem(22px);
	    margin-right: rem(8px);
	    background-repeat: no-repeat;
	}
	.checkout-link {
	    text-transform: uppercase;
	    float: right;
	    clear: none;
	    text-align: center;
	    color: $gray-lighter;
	    border-radius: 10px;
	    font-weight: bold;
	    text-decoration: none;
        font-size: 14px;/*IE8*/
	    font-size: .8rem;
	}
}

/* #SEARCH */

.site-search {
    text-align: right;
    margin-top: 9px !important;/*IE8*/
    margin-top: rem(9px) !important;
    position: relative;
    input.search-input::-webkit-input-placeholder {
        text-transform: uppercase;
    }
    input.search-input::-moz-placeholder {
        text-transform: uppercase;
    }
    input.search-input:-moz-placeholder {
        text-transform: uppercase;
    }
    input.search-input:-ms-input-placeholder {
        text-transform: uppercase;
    }
	.search-input {
        font-family: $font-stack--header;
        font-size: 15px;/*IE8*/
        font-size: rem(15px);
        padding: 5px 40px 5px 12px;/*IE8*/
	    padding: rem(5px) rem(40px) rem(5px) rem(12px);

        width: 320px;/*IE8*/
	    width: rem(320px);

        border: 1px solid scale-color($gray, $lightness: 75%);
        border-radius: 3px!important;
	    text-align: left;

        background-color: scale-color($gray, $lightness: 95%);
        color: scale-color($gray, $lightness: 25%);

        box-shadow: 0 3px 3px rgba($black, .10);
	    @include transition(0.2s, ease-in);
        @include reset_appearance;

        &:hover,
        &:focus {
            color: $gray;
            box-shadow: 0 4px 6px rgba($black, .15);
        }

        @media only screen and (max-width: 960px) {
            width: 240px;
            width: rem(240px);
        }
	}
	.search-button {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 25px;/*IE8*/
        height: 25px;/*IE8*/
	    width: rem(25px);
	    height: rem(25px);
	    background-color: lighten($gray, 20%);
	    background: url(bg(search)) center center no-repeat;
	    color: $white;
	    border: none;
	    cursor: pointer;
	    text-indent: -9999px;
	    text-transform: uppercase;
	}
}

.group-login {
	float: right;
}

@include media-minmax(801px, 1000px) {
    .site-search {
        clear: both;
        margin-bottom: 5px !important;
    }
    .site-controls .control-panel {
        padding: 0 3px;
    }
}
#searchbox .dismiss-search {
	display: none;
}
@include media-desktop {
    #header_top {
        position: relative;
    }
    .site-controls {
        position: relative;
    }
    
}

@include media-mobile {
    .site-header {
      border-bottom: 1px solid $gray-xtra-light;
        .site-header--top {
            float: left;
            width: 50%;
        }
        .site-header--bottom {
            float: right;
            width: 50%;
        }
    }
    .site-logo {
        margin-top: 2%;
        width: 85%;
    }
    .site-logo img {
        /*responsive logo*/
        max-width: 100%;
        max-height: 60px;
    }
    /*#searchbox {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        margin: 0!important;
        padding: 12px;
        text-align: center;
        z-index: 9001;
    }
    #searchbox.visible {
        display: block;
    }
    #searchbox .search-input {
        text-align: center;
        padding-right: 30px;
        border-color: scale-color($gray, $lightness: 55%);
        box-shadow: none;
        width: 100%;
    }
    #searchbox .search-button {
        position: absolute;
        top: 14px;
        right: 14px;
    }
    #searchbox .dismiss-search {
        display: none;
        position: absolute;
        height: 30px;
        width: 30px;
        padding: 5px;
        background-color: $gray-lighter;
        color: $white;
        right: 0;
        font-size: 30px;
        line-height: 15px;
        cursor: pointer;
    }*/
    #shoppingbag,
    #whathot_menu,
    #group_dropdown,
    #group_login_link,
    .trending,
    .adSpace {
        display: none !important;
    }
}

@include media-minmax(641px, 800px) {
    .site-header {
        height: 70px;
    }
    /*#searchbox {
        top: 35px;
    }
    #searchbox .dismiss-search {
        top: 50px;
    }*/
}
@include media-max(640px) {
    .site-header {
        height: 70px;
    }
	/*#searchbox {
		top: 25px;
	}*/
}
