/* Containers */

.panel {
    position: relative;
    max-width: 640px;/*IE8*/
    padding: 22px;/*IE8*/
    margin: 23px auto 22px auto;/*IE8*/
    max-width: rem(640px);
    padding: rem(22px);
    margin: rem(23px) auto (22px) auto;
    p {
        margin-bottom: 15px;/*IE8*/
        margin-bottom: rem(15px);
    }
}

.cards {
    @include flex-container(row);
    .card {
        padding: 15px;/*IE8*/
        padding: rem(15px);
    }
}
