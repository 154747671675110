.sizechart-brandnav {
  list-style: none;
  margin: 15px 0 30px 0;
}
.sizechart-brandnav li {
  display: inline-block;
  margin-right: 5px;
  padding-right: 9px;
  border-right: 1px dotted $gray-xtra-light;
}
.sizechart-brandnav li:last-of-type {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}
.sizechart-brandnav a {
  display: block;
}

.sizechart-brandbox {
  padding: 15px;
  border: 1px solid $gray-xtra-light;
  margin-bottom: 10px;
}

.sizechart-toplink {
  float: right;
  color: $white!important;
  padding: 5px;
  text-decoration: none;
  background-color: $teal-dark;
}

.sizechart-table thead tr {
  background-color: $black;
  color: $white;
}
.sizechart-table thead tr > th {
  border-color: $black;
}

.sizechart-details {
  background-color: lighten($gray-xtra-light, 10%);
}