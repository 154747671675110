/* Footer */
.js {
  position: relative;
  min-height: 100%;
}

.site-footer {
  width: 100%;
  float: left;
  border-top: 1px solid lighten($gray-lighter, 5%);
  border-bottom: 0;
  padding: 20px 0; /*IE8*/
  padding: rem(20px) 0;
  background-color: lighten($gray, 6%);
  color: $white;
	
	.js & {
    /* sticky footer */
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .footer-heading,
  .footer-text {
    margin: 0;
  }
  //#footer-links-wrapper .footer-heading {
  //  text-align: left;
  //}
  .footer-heading {
    font-size: 15px; /*IE8*/
    font-size: rem(15px);
    text-align: center;
    clear: both;
    line-height: 2;
    //text-transform: capitalize;
    text-transform: uppercase;
    color: darken($white, 0%);
    
    @at-root #footer-links-wrapper, #footer_customer_service {
      .footer-heading {
        text-align: left;
      }
    }
  }
  .footer-text {
    color: darken($white, 0%);
  }
  .footer-padded-content {
    padding: 10px 1% 5px 1%; /*IE8*/
    line-height: 18px; /*IE8*/
    padding: rem(10px) 1% rem(5px) 1%;
    line-height: 1.125rem;
    
    
    // Adjustments by GG
    width: 18%;
    float: left;
    &.site-footer--nav {
      width: 100%;
      //text-align: center;
      li {
        display: block;
        a, a:active, a:focus, a:hover, a:visited {
          display: block;
          padding: 4px 0;
          color: darken($white, 0%);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      
      @at-root .footer-heading {
        text-align: left;
      }
    }
  }
  .stay-connected {
    width: 22%;
    float: left;
  }
  .customer-care {
    width: 20%;
    float: left;
    //text-align: center;
    font-size: 14px; /*IE8*/
    font-size: rem(14px);
  }
  .customer-care a {
    &,
    &:active,
    &:focus,
    &:visited {
      color: $teal-dark;
    }
  }
  
  .footer-subscribe {
    //width: 60%; // Changed by GG
    width: 40%;
    float: left;
  }
  .subscribe--input,
  .subscribe--button {
    margin-left: auto;
    margin-right: auto;
  }
  .subscribe--input {
    color: lighten($gray, 10%);
    width: 60%;
    margin: 0 auto;
    padding: 5px; /*IE8*/
    padding: rem(5px);
    text-align: center;
  }
  .subscribe--button {
    @include reset_appearance;
    background-color: $gray-lighter;
    width: 25%;
    color: $white;
    border-radius: 1px;
    border: 1px solid lighten($gray, 72%);
    padding: 5px; /*IE8*/
    padding: rem(5px);
    cursor: pointer;
  }
  
  .site-footer--bottom {
    width: 100%;
    float: left;
    clear: both;
    color: lighten($gray, 12%);
    line-height: 16px; /*IE8*/
    font-size: 14px; /*IE8*/
    line-height: 1rem;
    font-size: 0.8rem;
    border-top: 0;
    text-align: center;
    .copyright {
      margin-right: 10px; /*IE8*/
      margin-right: rem(10px);
      margin-left: auto;
      line-height: 30px; /*IE8*/
      line-height: rem(30px);
      color: $white;
    }
    .site-footer--nav {
      width: 100% !important;
      li {
        line-height: 30px; /*IE8*/
        padding: 0 7px; /*IE8*/
        line-height: rem(30px);
        padding: 0 rem(7px);
        display: inline-block;
        text-decoration: none;
      }
      a {
        text-decoration: none;
        color: $white;
        opacity: .85;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .secure-logos {
    text-align: center;
    @media only screen and (max-width: 800px) {
      margin: 0 0 12px 0; /*IE8*/
      margin: 0 0 rem(12px) 0;
    }
    
    & > img {
      @media only screen and (min-width: 801px) {
        float: left;
      }
    }
    
    .ssl-logo {
      width: 80px;
    }
    
    .pci-logo {
      width: 110px;
    }
    
    .ssl-logo {
      @media only screen and (min-width: 801px) {
        width: auto;
        max-width: 65px;
      }
    }
    .pci-logo {
      @media only screen and (min-width: 801px) {
        width: auto;
        max-width: 110px;
      }
    }
  }
  /* Footer Social Icons */
  .social-icons-suite {
    float: left;
    width: 100%;
    margin-top: 5px; /*IE8*/
    margin-top: rem(5px);
    text-align: center;
    .social-icon {
      text-indent: 100%;
      white-space: nowrap;
      background-color: $teal-dark;
      background: url("/meta/pie/0004/0004/0777/icons/mini-social-icons_sm.png");
      display: inline-block;
      overflow: hidden;
      margin: 0 5px 0; /*IE8*/
      margin: 0 rem(5px) 0;
      padding: 0;
      width: 30px;
      height: 30px;
      display: inline-block;
      position: relative;
      float: none;
      @include transition(.15s, ease-in-out);
      @include disable_user_select;
    }
    .social-icon:after,
    .social-icon:before {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }
    
    @each $media_source, $bg-position in $social-media-icons {
      .social-icon.#{$media_source} {
        background-position: $bg-position;
      }
    }
  }
}

@include media-mobile {
  /* SITE FOOTER ON MOBILE VIEWPORT */
  .site-footer {
    padding-top: 0;
    width: 100%;
  }
  .site-footer--top {
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-flex-wrap: nowrap;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .site-footer--top > div {
    -ms-flex-item-align: center;
    -webkit-align-self: center;
    align-self: center;
    width: 100%;
    float: none;
  }
  .site-footer .footer-heading {
    /*font-size: 1.2rem;*/
    display: none;
  }
  .site-footer .footer-padded-content {
    padding: 4px 0px;
  }
  .footer-subscribe {
    text-align: center;
  }
  .footer-subscribe .subscribe--form {
    width: 100%;
  }
  .site-footer .footer-subscribe {
    width: inherit;
    float: none;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .site-footer .stay-connected {
    margin-top: 15px;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    float: left;
    width: 100%;
    padding: 15px;
    margin: 0px;
    margin-bottom: 4px;
  }
  .site-footer .customer-care {
    float: left;
    width: 100%;
    text-align: center;
    clear: both;
    padding: 0px;
    margin: 0px;
    margin-bottom: 4px;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .site-footer .copyright {
    display: none !important;
  }
  .site-footer .social-icons-suite {
    float: left;
    clear: both;
  }
  .site-footer .social-icons-suite .social-icon {
    float: none;
  }
  .stay-connected,
  .footer-subscribe {
    margin: 4px 0;
    float: none;
  }
  .site-footer--nav {
    display: none;
    /*        float: none;
    display: block;
    width: 100%;*/
  }
  .site-footer--nav li {
    float: left;
  }
}

/*  .social-icons-suite .social-icon:hover    { background-color:#FF3570; }*/

// .social-icons-suite .social-icon.facebook:hover {
//     background-position: 0px -89px;
// }

// .social-icons-suite .social-icon.twitter:hover {
//     background-position: -240px -89px;
// }

// .social-icons-suite .social-icon.pinterest:hover {
//     background-position: -150px -89px;
// }

// .social-icons-suite .social-icon.instagram:hover {
//     background-position: -330px -89px;
// }

// .social-icons-suite .social-icon.google:hover {
//     background-position: -90px -89px;
// }

// .social-icons-suite .social-icon.blog:hover {
//     background-position: -180px -89px;
// }

// .social-icons-suite .social-icon.youtube:hover {
//     background-position: -300px -89px;
// }

// .social-icons-suite .social-icon.linkedin:hover {
//     background-position: -120px -89px;
// }
