/*******************
    Quick Look
*******************/

.ql {
	.fancybox-inner {
		overflow-x: hidden !important;
	}
	.fancybox-skin {
		/* Custom */
		background-color: $white;
	}
	.ql-block {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.ql-visual,
	.ql-info {
		float: left;
		padding: 10px 10px 0 10px; /*IE8*/
	;
		padding: rem(10px) rem(10px) 0 rem(10px);
	}
	.ql-visual {
		position: relative;
		width: 32%;
	}
	
	.ql-visual .icon--new {
		width: 60px;
		height: 60px;
		top: 15px;
		right: 10px;
	}
	
	.ql-info {
		width: 68%;
	}
	
	.ql-info .heading {
		font-size: 25px; /*IE8*/
		font-size: rem(25px);
	}
	
	.ql-info .sc {
		font-size: 17px;
		font-size: rem(17px);
		font-weight: $font-weight--bold;
		color: lighten($gray, 45%);
		margin: 12px;
		margin: rem(12px) 0;
	}
	
	.ql-info .branding {
		float: left;
		position: relative;
		top: -5px;
		background-repeat: no-repeat;
	}
	
	.ql-info p {
		line-height: 1.428;
		margin-bottom: 8px;
		margin-bottom: rem(8px);
	}
	
	.ql-info .ql-selectors {
		margin-bottom: 10px;
	}
	
	.ql-info .sz-selector,
	.ql-info .qty-selector {
		background: $white;
		// text-align: center;
		border-radius: 2px;
		padding: 3px;
		border: 1px solid lighten($gray, 30%);
		cursor: pointer;
		color: $black;
		outline: none;
	}
	.ql-cart-add {
		margin: 22px 0; /*IE8*/
		margin: rem(22px) 0;
	}
	
	.ql-visual img {
		display: block;
		margin: auto;
		border: 1px solid darken($white, 12%);
	}
	.ql-viewmore {
		margin-top: 12px;
		margin-top: rem(12px);
		a {
			background-color: darken($white, 5%);
			max-width: 240px;
		}
	}
	@include media-mobile {
		.ql-visual,
		.ql-info {
			float: none;
			width: inherit;
		}
	}
}

/* *******************
	product page
********************** */

/*Top breadcrumbs*/

#breadcrumbs {
	padding: 10px 5px;
	font-size: 14px; /*IE8*/
	font-size: .85rem;
	margin-bottom: 1%;
	a {
		text-decoration: none;
		color: #15BDA4 !important;
		margin: 0 2px 0 5px;
	}
}

/*Product Container*/

#product {
	color: $gray-lighter;
	/*test*/
	margin-bottom: 35px;
	a,
	p,
	li {
		color: $gray-lighter;
	}
	p {
		margin: 0;
	}
	.label {
		font-size: 15px; /*IE8*/
		font-size: .9rem;
	}
	
	.heading {
		font-weight: $font-weight--bold;
		margin-bottom: 5px;
	}
	
	.tech {
		max-width: 100px;
		width: 30%;
		min-height: 50px;
		max-height: 80px;
		background-repeat: no-repeat;
		background-position: center;
		text-indent: 100%;
		overflow: hidden;
		&.tech-certainty {
			background-image: url(/meta/pie/0004/0004/0002/cert_LG.png);
			height: 66px;
		}
		
		&.tech-certainty-plus {
			background-image: url(/meta/pie/0004/0004/0002/certplus_LG.png);
		}
		
		&.tech-apma {
			background-image: url(/meta/pie/0004/0004/0002/apma.png);
			height: 77px;
			margin-top: -5px;
		}
	}
	
	.tech-container {
		margin: 10px 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.tech-description {
		width: 70%;
		margin-top: 10px;
	}
	/* Add to cart */
	.add-cart-btn {
		text-transform: uppercase;
		font-size: 20px; /*IE8*/
		font-size: 1.6rem;
		padding: 12px 45px;
		width: 360px;
		max-width: 100%;
	}
}

#product_images,
#product_info {
	padding: 0 10px;
}

#product_info {
	padding: 15px;
	box-shadow: 0px 2px 4px rgba($black, 0.12);
	p,
	li {
		line-height: 1.24;
		font-size: 15px; /*IE8*/
		font-size: .9rem;
	}
	p,
	span {
		&.out-of-stock {
			padding: 20px;
			background-color: darken($white, 10%);
			border: 1px solid darken($white, 15%);
			color: #888;
			font-size: 18px; /*IE8*/
			font-size: 1.3rem;
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;
		}
	}
	.brand-name,
	.product-title {
		margin: 0;
	}
	.brand-name {
		display: inline-block;
		color: $gray-lighter;
		border-bottom: 1px solid;
		font-size: 15px; /*IE8*/
		font-size: .925rem;
		line-height: 1;
		text-transform: uppercase;
	}
	
	.product-title {
		font-weight: $font-weight--bold;
		text-transform: capitalize;
		color: #333;
		font-size: 20px; /*IE8*/
		font-size: 1.3rem;
	}
	.tech-style-label {
		margin-left: 25%;
	}
	
	.tech-style-id {
		padding-left: 5px;
	}
}

.ql .product-price,
#product_info .product-price {
	font-size: 18px; /*IE8*/
	font-size: 1.2rem;
	font-weight: bold;
	color: $success;
}

/*Color selector*/

#color_container {
	.swatch-captions {
		font-size: .9rem;
		text-transform: uppercase;
		color: #8b8b8b;
		margin: 0;
		padding: 0;
	}
	
	.swatches {
		width: 100%;
		margin: 0;
		.mod-flexbox & {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}
	}
	
	.swatch {
		border: 1px solid transparent;
		font-size: 0;
		padding: 2px;
		width: 30px;
		float: left;
		@include transition(.25s, ease);
	}
	
	.swatch span {
		width: 24px;
		height: 24px;
		display: block;
	}
	
	.swatch img,
	.swatch span {
		outline: 1px solid $gray-light;
	}
	
	.swatch.first {
		margin-left: 0;
	}
	
	.swatch.last {
		margin-right: 0;
	}
	
	.swatch.selected,
	.swatch:hover {
		transform: scale(.89);
		border-color: #333;
	}
	
	.swatch a {
		display: block;
	}
}

/* Quantity Selector */
#qty_container {
	.qty-label,
	.qty-selector,
	.group-orders {
		margin: 0 15px 0;
	}
	.qty-selector {
		background: #fff;
		// text-align: center;
		border-radius: 2px;
		padding: 3px;
		border: 1px solid $gray-lighter;
		color: $gray-lighter;
		outline: none;
		cursor: pointer;
		width: 50px;
	}
	.group-orders {
		text-transform: uppercase;
		text-decoration: none;
		font-weight: bold;
		font-size: 14px; /*IE8*/
		font-size: .8rem;
	}
}

/* Embroidery container */
.embroidery {
	// border: 1px solid $gray-xtra-light;
	background-color: lighten($gray, 95%);
	background-color: rgba($black, .03);
	border: 1px solid lighten($gray, 92%);
	border: 1px solid rgba($black, .04);
	.embroidery-logo {
		max-height: 70px;
	}
	.embroidery-markup {
		vertical-align: middle;
		color: $success;
		font-weight: $font-weight--bold;
		line-height: 2;
	}
	.embroidery-markup-bottom {
		display: block;
	}
	.label {
		font-weight: $font-weight--bold;
	}
}

/* Size container */

/* Size grid */

.fit-options {
	list-style: none;
	margin: 0;
	padding: 0 0 10px 0;
	li {
		//display: inline-block;
		//padding-right: 10px;
		//margin-right: 10px;
		//border-right: 1px solid $gray-light;
		//line-height: 1;
		opacity: 0.3;
		margin: 5px 0;
		@include transition(all 150ms ease-in-out);
		
		&.exact_match,
		&.color_match {
			opacity: 1;
		}
		&.variant_has_color {
			opacity: 1;
			a {
				font-weight: 900;
			}
		}
		
		&:hover {
			opacity: 1;
			a {
				background-color: rgba(0, 0, 0, 0.04);
				border-radius: 2px;
				border: 1px solid rgba(51,51,51,.11);
				margin: -1px -6px;
				@include transition(background-color 150ms ease-in-out);
			}
		}
	}
	
	li,
	a {
		color: $gray-light !important;
		@include transition(.1s, ease);
	}
	
	li:last-child {
		//padding-right: 0;
		//margin-right: 0;
		//border-right: 0;
	}
	
	.selected,
	li:hover a {
		color: $black !important;
	}
	.selected {
		margin: 2px -6px;
		padding: 5px;
		border-radius: 2px;
		border: 1px solid rgba(51,51,51,.11);
		background-color: #6592c6;
		img {
			float: left;
			height: 30px;
			width: 30px;
			border-radius: 30px;
			border: 2px solid #ffffff;
		}
		span {
			float: left;
			line-height: 2.1;
			span {
				padding-left: 9px;
				font-weight: 900;
				color: #ffffff;
			}
		}
		&:before, &:after {
			content: '';
			display: table;
			clear: both;
			float: none;
		}
	}
	
	a {
		display: block;
		text-decoration: none;
		line-height: 2.1;
		margin: 0 -5px;
		padding: 5px;
		@include transition(background-color 150ms ease-in-out);
		img {
			float: left;
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
		span {
			float: left;
			padding-left: 10px;
		}
		&:before, &:after {
			content: '';
			display: table;
			clear: both;
			float: none;
		}
	}
}

#size_grid {
	padding-right: 5%;
	.mod-flexbox & {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: center;
	}
	li.disabled1 a:hover {
		border-color: red;
		color: red;
		background-color: rgba(255, 0, 0, 0.1);
	}
	.size {
		min-width: 36px;
		display: inline-block;
		margin: 1% 1% 1% 0;
		
		&.disabled1 {
			&.date-available, &.out_of_stock {
				pointer-events: auto !important;
				
				&.out_of_stock {
					pointer-events: auto !important;
					
					a {
						cursor: not-allowed !important;
					}
				}
				
				a {
					cursor: not-allowed !important;
				}
			}
		}
		
		&:not(.disabled1) a {
			border-color: #333333;
			color: #333333;
			background-color: #ffffff;
		}
		&:not(.disabled1) a:hover {
			border-color: #333333;
			color: #fff;
			background-color: #acc0d7;
			font-weight: 600;
		}
		&.selected:not(.disabled1) a {
			border-color: #163f25;
			color: #fff;
			background-color: #539d6e;
			font-weight: 600;
		}
	}
	
	.size:first-child,
	.size:nth-child(6n) {
		margin-left: 0;
	}
	
	.size:last-child {
		margin-right: 0;
	}
	
	.size:nth-child(6n) {
		clear: left;
	}
	
	.size a {
		color: lighten($gray-light, 20%);
		display: block;
		text-align: center;
		text-decoration: none;
		padding: 5px;
		border: 1px solid;
		text-transform: uppercase;
		@include transition(.38s, ease);
		font-size: 14px;
	}
	
	.out-of-stock a {
		color: $gray-light !important;
		border-color: $gray-light !important;
	}
	
	.selected a,
	a:hover {
		color: $black;
		border-color: $black;
	}
	
}

/* Product features list */

#product_features {
	margin-bottom: 4%;
	ul {
		list-style: disc outside;
		padding: 0 0 0 16px;
	}
	li {
		padding: 5px 0;
		border-bottom: 1px dotted rgba($black, .1);
		word-wrap: break-word;
		&:last-child {
			border-bottom: 0;
		}
	}
}

/* Size chart and size chart controls */
#size_chart {
	p {
		margin: 10px 0;
		font-weight: $font-weight--bold;
	}
	
	table {
		width: 100%;
		border-collapse: collapse;
	}
	
	th {
		background-color: darken($white, 10%);
		font-weight: $font-weight--bold;
	}
	
	td,
	th {
		padding: 6px 10px;
		border: 1px solid darken($white, 15%);
		text-align: center;
	}
}

#show_size_chart {
	text-transform: uppercase;
	text-decoration: none;
	padding: 5px 0;
	-webkit-transition: .15s ease;
	transition: .15s ease;
	display: inline-block;
	text-decoration: underline;
}

#product_images {
	position: relative;
	padding-bottom: 25px;
}

#product .captions {
	color: #999;
	padding-top: 16px; /*IE8*/
	padding-top: 1em;
	text-align: center;
}

#multiple_image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 460px;
	overflow-y: auto;
}

#multiple_image::-webkit-scrollbar {
	width: 12px;
}

#multiple_image::-webkit-scrollbar-track {
	background-color: #f7f7f7;
}

#multiple_image::-webkit-scrollbar-thumb {
	background-color: #b2b2b2;
}

#multiple_image .thumb-image {
	padding: 4px;
	width: 80px;
	border: 2px solid transparent;
	font-size: 0;
	cursor: pointer;
	text-align: center;
	
	img {
		max-height: 80px;
	}
}

#multiple_image .selected {
	border-color: #2d2d2d;
}

#multiple_image img {
	border: 1px solid #eee;
}

#single_image {
	position: relative;
	max-width: 450px;
	min-width: 300px;
	max-height: 600px;
	width: 50%;
	margin: auto;
}

#single_image img {
	max-height: 500px;
	display: block;
	margin: auto;
}

#single_image .icon--new {
	right: 12%;
	bottom: 0;
	width: 50px;
	height: 50px;
}

#single_image a {
	display: block;
}

/* Product image slide arrows */

#product_images .slide-ctrl {
	display: none;
	position: absolute;
	top: 45%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	outline: none;
	line-height: 40px;
	padding: 0 5px;
	background-color: #eee;
	color: #999;
	font-size: 40px;
	z-index: 501;
	height: 60px;
	box-shadow: 0 1px 1px #aaa;
}

#product_images .slide-prev {
	left: 10%;
}

#product_images .slide-next {
	right: 10%;
}

/* slick carousel - prevent from showing blue borders */

#product_images .slick-slide:focus,
#product_images .slick-slide:active {
	border: none;
	outline: none;
}

#product_images .icon-new {
	top: 10px;
	right: 100px !important;
	width: 60px;
	height: 60px;
}

#product_images .social-share {
	z-index: 101;
	position: absolute;
	bottom: -40px;
	left: -5%;
	right: 0;
	text-align: center;
}

#product_images .social-share .stButton .stLarge {
	height: 26px;
	width: 26px;
	background-size: 26px;
	-webkit-transition: .2s ease;
	transition: .2s ease;
}

#product_images .social-share .stButton .stLarge:hover {
	background-position: 0;
	opacity: .85;
}

#product_images .social-share .st_sharethis_custom {
	text-indent: 100%;
	white-space: nowrap;
	background-color: #008262;
	background: url("/meta/pie/0004/0004/0777/icons/mini-social-icons_sm.png") no-repeat -30px 0 transparent;
	background-repeat: no-repeat;
	background-position: 0 0;
	display: inline-block;
	overflow: hidden;
	margin: 0 5px 0px 0;
	padding: 0;
	width: 30px;
	height: 30px;
	display: inline-block;
	position: relative;
	float: none;
	-webkit-transition: .15s ease-in-out;
	transition: .15s ease-in-out;
	cursor: pointer;
}

#product_images .social-share .facebook {
	background-position: 0px -59px;
}

#product_images .social-share .twitter {
	background-position: -240px -59px;
}

#product_images .social-share .pinterest {
	background-position: -150px -59px;
}

#product_images .social-share .instagram {
	background-position: -330px -59px;
}

#product_images .social-share .google {
	background-position: -90px -59px;
}

#product_images .social-share .blog {
	background-position: -180px -59px;
}

#product_images .social-share .youtube {
	background-position: -300px -59px;
}

#product_images .social-share .linkedin {
	background-position: -120px -59px;
}

/* Complete the outfit */

#complete_outfit {
	margin-top: 40px;
}

#complete_outfit_cycle {
	height: 320px;
}

#complete_outfit .headline {
	font-style: italic;
	color: #aaa;
	font-weight: 400;
	margin-bottom: 10px;
	font-size: 1.725rem;
}

#complete_outfit .title {
	height: 40px;
}

#complete_outfit .featured-item--image {
	height: auto;
}

#complete_outfit .featured-item--image img {
	max-width: 180px;
	height: 170px;
	max-height: 170px;
	margin: auto;
}

#complete_outfit .nav-arrow {
	top: 30%;
	top: calc(50% - 5rem);
}

@media only screen and (min-width: 801px) {
	.product-page {
		padding-left: 10px;
		padding-right: 10px;
	}
	#single_image {
		height: 500px;
	}
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
	#product_images {
		width: 57%;
	}
	#product_info {
		width: 42%;
	}
	#product_info .add-to-cart-btn {
		font-size: 1.2rem;
	}
	#single_image {
		height: initial;
	}
	#multiple_image .thumb-image {
		width: 50px;
	}
}

@media only screen and (max-width: 800px) {
	#breadcrumbs {
		padding: 5px;
		width: 100%;
		font-size: .8rem;
	}
	#complete_outfit {
		display: none;
	}
	#product_images,
	#product_info {
		float: none;
		width: 100%;
	}
	#product_images {
		padding-bottom: 0;
	}
	#product_images .social-share {
		position: absolute;
		width: 40px;
		top: 50px;
		left: -5%;
	}
	#product_images .social-share span {
		display: block;
	}
	#single_image {
		display: block;
	}
	#single_image,
	#single_image img {
		height: 280px;
	}
	#single_image img {
		display: block;
		margin: auto;
		/* for unobtrusive scrolling */
		position: relative;
		// z-index: -1;
	}
	#multiple_image {
		display: none;
	}
	/* Size chart on mobile  */
	#size_chart td,
	#size_chart th {
		font-size: .8rem;
		padding: 3px;
	}
	#size_chart {
		overflow-x: auto;
	}
	#product_info {
		margin: 0;
		box-shadow: none;
		padding: 5px 15px 10px 15px;
	}
	#product_info .product-title {
		font-size: 1.1rem;
	}
	#product_info .brand-name {
		font-size: .85rem;
	}
	#product_info .add-cart-btn {
		width: 360px;
	}
	#product_info .content-box {
		margin-bottom: 3%;
	}
	#size_chart {
		text-align: center;
	}
	#product_info .add-cart-btn {
		width: 100%;
	}
}

@media only screen and (max-width: 480px) {
	#is_new_product {
		top: 0;
		right: 25px;
	}
}



/*product*/
.msrp-row {color:#5A8AC2;font-size:90%;}