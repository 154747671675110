/* **************
	Tophat
**************** */
.site-tophat {
	background-color: lighten($gray, 10%);
	line-height: 30px;/*IE8*/
	line-height: rem(30px);
	.tophat-item {
	    white-space: nowrap;
	    text-decoration: none;
	}

	a {
		overflow: hidden;
	}
	a:hover {
		text-decoration: underline;
	}

	a,
	span {
		color: $gray-light;
		text-decoration: none;
		font-size: inherit;
		padding-right: 15px;/*IE8*/
		padding-right: rem(15px);
		&:last-child {
			padding-right: 0;
		}
	}
	@include media-mobile {
		display: none;
	}
}
