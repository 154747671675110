/* misc styles to fix nav/logo/icons alignment issue updated 2017-01-27 by HY */

body {
    overflow-x: hidden;
}

/*Toggle Panel*/

.togglePanel {
    display: none;
}


/* General Menu Styling */
#header {
    box-sizing: border-box;
}
#menu {
    position: relative;
}

#menu .menu .label {
    color: $black;
}

@include media-mobile {
    #menu_back {
        display: none;
    }
    #menu {
        left: 0;
        width: 100%;
        text-transform: uppercase;
        text-indent: 10px; /*IE8*/
        text-indent: rem(10px);
        margin-bottom: 0;
        margin-top: 5px; /*IE8*/
        margin-top: rem(5px);
        padding: 0;
        position: initial;
    }
    #mobile_close {
        position: absolute;
        top: 5px;
        right: 12px;
        color: $gray;
        line-height: 35px; /*IE8*/
        line-height: rem(35px);
        font-size: 45px; /*IE8*/
        font-size: rem(45px);
        cursor: pointer;
    }
    // #mobile_close:hover,
    // #mobile_close:focus{
    //     color: #fff;
    //     background-color: $gray;
    // }
    #profile_icon { background-image: url(bg(profile-icon)); }
    #search_icon { background-image: url(bg(search)); }
    #bag_icon { background-image: url(bg(shopping-bag)); }
    #menu_icon { background-image: url(bg(menu-icon)); }
    .mobile-menu {
        width: 100%;
        line-height: rem(50px);
        cursor: pointer;
        border-bottom: 0;
        color: lighten($gray, 20%);
        margin-top: rem(10px);
        margin-bottom: 0;
        text-align: left;
        .tool-icon {
            float: right;
            margin: 0 3%;
            width: 20px;
            height: 20px;
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;
        }
        .tool-icon > a {
            display: block;
            height: inherit;
            padding: 5px 0;/*IE8*/
            padding: rem(5px) 0;
        }
    }
    .mobile-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9997;
        background-color: $black;
        background-color: rgba($black, .75);
        opacity: 1;
        @include transition (right 400ms ease-in-out);
        &.hidden {
            opacity: 0;
        }
    }
    /*.mobile-dropdown {
        display: block;
        width: 90%;
        height: auto;
        max-height: 100%;
        position: fixed;
        top: 0;
        right: -100%;
        padding: 0;
        border: 0;
        margin-top: 0;
//        background-color: $white;
        background-color: transparent;
        z-index: 9999;
        box-shadow: -5px 1px 9px rgba($black, .2);
        overflow-y: auto;
        @include transition (right 400ms ease-in-out);
        &.slide-left {
            @include transition (right 300ms ease-in-out);
            right: 0;
        }
        li {
            display: block;
            font-size: .7rem;
            font-size: 14px;
            opacity: 1;
        }
        .menu {
            float: left;
            width: 100%;
            clear: both;
            padding: 0;
            background: #fff;
        }
        .menu .label,
        .menu a {
            display: block;
        }
        .menu a {
            height: inherit;
            padding: 5px 0;!*IE8*!
            padding: rem(12px) 0;
            text-decoration: none;
        }
        .menu > .label {
            background-color: darken($white, 5%);
            font-size: 15px;!*IE8*!
            //font-size: rem(15px);
            font-size: 1.2rem;
            color: $black;
            border-bottom: 1px solid rgba($black, .1);
            font-weight: $font-weight--bold;
            text-align: left;
            padding: 12px 0;!*IE8*!
            padding: rem(12px) 0;
            width: 100%;
            cursor: pointer;
            &:hover{
                background-color: #C3C3C3; //added by HY
            }
        }
        .userstuff-mobile > .label {
            background-image: url("/meta/pie/0004/0004/0777/icons/user_icon.png"), url("/meta/pie/0004/0004/0777/icons/expand_more.png");
            background-position: 10px center, 99% center;
            background-repeat: no-repeat;
            background-size: 20px 20px, 30px 30px;
            padding-left: 25px;
        }
        .mobile-signin > .label {
            background-color: scale-color($black, $lightness: 10%);
            border-color: $black;
            &:hover{
                background-color: #000;
            }
        }
        // .mobile-signin > .mobile-logout {
        //     background-color: scale-color($danger, $lightness: 30%, $saturation: -35%);
        // }
        .mobile-signin > .label a {
            text-decoration: none;
            color: $white;
            display: block;
            height:100%;
            padding: 0 //added by HY
        }
        .menu .subLabel {
            font-weight: $font-weight--bold;
            font-size: 16px;!*IE8*!
            margin-bottom: 5px;!*IE8*!
            font-size: rem(16px);
            margin-bottom: rem(5px);
            &:hover {
                background-color: transparent;
            }
        }
        .profile {
            display: none;
        }
        .content {
            width: 100%;
            padding: 12px;!*IE8*!
            padding: rem(12px);
            border: 0 !important;
        }
        .content .swatch {
            float: left;
            clear: both;
            width: 100%;
        }
        .content .cols {
//            width: 50%;
            width: 100%;
            float: left;
            color: $gray;
            margin-bottom: 20px;!*IE8*!
            margin-bottom: rem(20px);
        }
        .content .catchAll {
            width: 100%;
            float: left;
            clear: both;
            padding-top: 10px;!*IE8*!
            margin-top: -8px;!*IE8*!
            padding-top: rem(10px);
            margin-top: rem(-8px);
            text-align: left;
            text-indent: 0;
            a {
                text-decoration: none;
                color: $primary;
                padding-left: 8px;!*IE8*!
                padding-left: rem(8px);
                text-transform: capitalize;
            }
        }
    }*/
    #colorPanel {
        width: 100%;
        margin: 0 0 10px;/*IE8*/
        margin: 0 0 rem(10px);
    }
    #shopBy,
    #login {
        display: none;
    }
    .menu-dropdown a{
        &:hover{
            background: #E2FBFF;
        }
    }

}




/* large format menu */
@include media-desktop {
    #menu {
        padding: 0 10px;/*IE8*/
        padding: 0 rem(10px);
    }
    .userstuff-mobile {
        display: none;
    }
    #menu_back {
        position: absolute;
        display: block;
        height: 100%;
        width: 9999px;
        left: -1000px;
        border-top: 1px solid $gray-light;
        border-bottom: 1px solid $gray-light;
    }
    .mobile-overlay,
    #mobile_close {
        display: none;
    }
    .site-header--top,
    .site-controls {
        position: relative;
    }
    .mod-flexbox #menu_content {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        .menu {
            margin: 0;
        }
        .label {
            margin-right: 25px;/*IE8*/
            margin-right: rem(25px);
        }
        .shopby {
            -webkit-order: 1;
                -ms-flex-order: 1;
                    order: 1;
        }
        .user-stuff {
            -webkit-order: 2;
                -ms-flex-order: 2;
                    order: 2;
            margin-left: auto;
        }
    }
    .mod-no-flexbox #menu_content{
        .menu {
            width: auto;
            margin: auto;
            display: inline-block;
            float: left;
            clear: none;
            padding-right: 0;
            text-align: left;
            &.user-stuff {
                float: right;
            }
        }
        .mobile-signin {
            display: none!important;
        }
    }
    /*#menu .login.signin-ctrl:hover:after{ display: block !important; }*/
    #menu {
        .mobile-signin {
            /* Do not display group login link on desktop menu */
            /* Show the link in the dropdown instead */
            display: none;
        }
        a {
            color: $gray;
            &:hover {
              color: $black;
            }
        }

        li:last-child {
            border-bottom-color: $white;
        }

        li:hover:last-child {
            border-bottom-color: $teal;
        }
        .label {
            z-index: 0;
            position: relative;
            padding: 8px 0;/*IE8*/
            padding: rem(8px) 0;
            margin-right: 50px;
            display: block;
            cursor: pointer;
            text-transform: uppercase;
            a {
                text-decoration: none;

                //&:hover {
                //    color: #58CC72;
                //}
            }
        }
        .label:after {
            content: "";
            display: block;
            position: absolute;
            z-index: 100;
            bottom: -1px;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin-left: auto;
            margin-right: auto;
            border-top: 4px solid transparent;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $black;
            visibility: hidden;
            opacity: 0;
            transition: .1s ease-in .15s;
        }
        .menu:hover .label:after {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        .shopby {
            #cMenu,
            #bMenu,
            #colMenu {
                color: $primary;
            }
            &:hover .label:after {
                display: none;
            }
            .shopbyHeader {
                float: left;
                width: 100%;
                clear: both;
                margin: -1% 0 1% -1%;
            }
            .shopbyHeader h2 {
                width: 100px;/*IE8*/
                width: rem(100px);
                float: left;
                clear: none;
                margin-top: 8px;/*IE8*/
                margin-top: rem(8px);
            }
            .shopbyHeader span {
                display: block;
                width: 120px;/*IE8*/
                width: rem(120px);
                border: 1px solid darken($white, 20%);
                margin: 8px 5px;/*IE8*/
                padding: 3px;/*IE8*/
                margin: rem(8px) rem(5px);
                padding: rem(3px);
                float: left;
                clear: none;
                text-indent: 5px;
                text-transform: lowercase;
                color: $gray;
                cursor: pointer;
            }
            .shopbyHeader span:hover {
                background-color: darken($white, 10%);
            }
        }

        .nodropdown .label:after {
          display: none!important;
        }

        .sub .label {
            font-size: 12px;/*IE8*/
            padding: 8px 0;/*IE8*/
            font-size: rem(12px);
            padding: rem(8px) 0;
            color: $gray;
            background-color: transparent;
        }

        .red .label {
            color: $danger;
            a {
                color: $danger;
            }
        }
        .right .label {
            margin: 0;
        }
        .nopadding .label {
            padding-left: rem(4px);
            padding-right: rem(4px);
            padding-left: 4px;/*IE8*/
            padding-right: 4px;/*IE8*/
            margin-right: 0;
        }
        .profile .toggle:after {
            content: "\025BE";
            display: inline;
            margin-left: 1px;/*IE8*/
            margin-left: rem(1px);
            font-size: ($base-font-size * 1.25);
        }
        .profile .avatar {
            display: inline-block;
            vertical-align: middle;
            margin: 1px 0;/*IE8*/
            width: 50px;/*IE8*/
            height: 38px;/*IE8*/
            margin: rem(1px) 0;
            width: rem(50px);
            height: rem(38px);
            overflow: hidden;
        }
        .heading {
            display: none;
        }
        .signin-panel {
            cursor: pointer;
        }
        .nav-banners {
            .img-placeholder {
                background: lighten($gray,10%) !important;
            }
            img {
                box-shadow: 0 4px 2px rgba($black, .12);
                display: block;
                margin: 0 auto 5px auto;
            }
            li {
                min-height: 150px;/*IE8*/
                max-width: 150px;/*IE8*/
                min-height: rem(150px);
                max-width: rem(150px);
                margin: 0 1% !important;
                float: left;
                clear: none !important;
            }
            &.nav-banners-2up li {
                width: 50%;
            }
            &.nav-banners-full li {
                width: 16%;
            }
            &.nav-banners-full span {
                font-size: 12px;/*IE8*/
                font-size: rem(12px);
                width: 90%;
                margin: auto;
                text-align: center;
                display: block;
            }
            a {
                display: block;
                &:hover {
                    background: darken($white, 10%);
                }
            }
        }
        .content {
            background-color: $white;
            border: 1px solid $black;
            border-top: 4px solid $black;
            li {
                width: 100%;
                text-align: left;
                margin: 0;
                border-bottom: 0;
                text-transform: capitalize;
                float: left;
                clear: both;
            }

            li a:hover {
                background-color: $teal;
            }
            .subLabel {
                font-size: 15px;/*IE8*/
                line-height: 34px;/*IE8*/
                font-size: rem(15px);
                line-height: rem(34px);
                font-weight: 800;
                text-align: left;
                width: 100%;
                float: left;
                clear: none;
                &:hover {
                    background-color: transparent;
                }
            }
            .catchAll {
                width: 100%;
                float: left;
                clear: both;
                padding-top: 10px;/*IE8*/
                margin-top: 2px;/*IE8*/
                padding-top: rem(10px);
                margin-top: rem(2px);
            }

            .catchAll a {
                text-decoration: none;
                color: $primary;
                padding-left: 8px;/*IE8*/
                padding-left: rem(8px);
                text-transform: capitalize;
                &:hover {
                    text-decoration: underline;
                }
            }

            .adSpace {
                width: 35%;
                margin: 10px 0;/*IE8*/
                margin: rem(10px) 0;
                float: left;
                clear: none;
                display: inline-block;
                &.fullwidth {
                    width: 100%;
                }
            }

            .swatch {
                float: left;
                clear: none;
                width: 100%;
            }
            .swatch span {
                display: block;
                float: left;
                clear: none;
                color: lighten($gray, 25%);
                line-height: 30px;/*IE8*/
                text-indent: 8px;/*IE8*/
                line-height: rem(30px);
                text-indent: rem(8px);
            }
            .swatch:hover span {
                color: $black;
            }
            .swatch a {
                float: left;
                clear: left;
                width: 100%;
                padding: 2px 0;/*IE8*/
                padding: rem(2px) 0;
                &:hover {
                    background-color: darken($white, 15%);
                }
            }
            .swatch img {
                text-indent: 0;
                width: 24px;
                height: 24px;
                padding: 0;
                margin: 1px;
                border: 1px solid darken($white, 20%);
                float: left;
                clear: left;
            }
            .swatch:hover img {
                border: 1px solid ($white, 20%);
            }

            .SBbrandBar {
                width: 100%;
                clear: both;
                float: left;
                text-align: center;
                padding: 6px 0 6px;/*IE8*/
                padding: rem(6px) 0 rem(6px);
                margin: 1% 0 1%;
            }
            .SBbrandBar .brandbar-item{
                text-indent: -9999px;
                overflow: hidden;
            }
        } // end of .content

        .cols {
            width: 15%;
            float: left;
            clear: none;
            margin-right: 4%;
            .head {
                font-size: .95rem;
                color: $black;
                margin: 0;
                line-height: 1.8;
                font-weight: $font-weight--bold;
            }
            &.sColor {
                width: 35%;
                float: right;
                clear: right;
                margin-top: -12px;
                padding: 5px 20px;/*IE8*/
                padding: rem(5px) rem(20px);
                margin-right: -2%;
                //min-height: 350px;/*IE8*/
                //min-height: rem(350px);

                background-color: $white;
                background: url(bg(dot-texture)) repeat;
            }
            &.sColor .swatch {
                width: 50%;
                clear: left;
            }
            &.sColor .imgPre {
                float: right;
                width: 40%;
                margin: 1%;
                height: 280px;/*IE8*/
                height: rem(280px);
                overflow: hidden;
            }
            &.sColor .imgPre img {
                height: 80%;
            }
            &.sColor span {
                color: $gray;
            }
            &.sColor .subLabel {
                font-size: 18px;/*IE8*/
                font-size: rem(18px);
                font-weight: $font-weight--bold;
                color: $gray;
            }

            &.brands {
                width: 15%;
                clear: none;
                padding: 0 1% 0 0;
                margin: 0 -6% 2% 6%;
                &.span-2 {
                    width: 30%;
                }
                &:first-child {
                    margin-left: 0;
                }
            }

            &.brands a {
                color: lighten($gray, 20%);
                padding: 2px;/*IE8*/
                text-indent: 8px;/*IE8*/
                line-height: 28px;/*IE8*/
                font-size: 15px;/*IE8*/
                padding: rem(2px);
                text-indent: rem(8px);
                line-height: rem(28px);
                font-size: rem(15px);
                float: left;
                display: block;
                width: 100%;
                text-decoration: none;
                &:hover {
                    background-color: darken($white, 5%);
                    color: $black;
                }
            }
            &.brandShop {
                float: right;
                width: 20%;
            }
            &.brandShop a {
                padding-left: 10px;/*IE8*/
                margin: 2px 0;/*IE8*/
                padding-left: rem(10px);
                margin: rem(2px) 0;
                background-color: darken($white, 20%);
                float: right;
                width: 100%;
            }

            .subLabel {
                background-color: transparent;
                float: left;
                width: 100%;
                display: block;
                line-height: 30px;/*IE8*/
                line-height: rem(30px);
                &:hover {
                    background-color: transparent;
                }
            }
        } // end of .cols

        .submenu {
            display: block;
        }

        .colorPanel {
            margin-bottom: 1%;
        }
    } // end of #menu
    /*User logged in - dropdown controls*/
    #logged_in,
    #login {
        padding-left: 5px;/*IE8*/
        padding-right: 5px;/*IE8*/
        padding-left: rem(5px);
        padding-right: rem(5px);
        .account-dropdown {
            position: absolute;
            right: 0;
            background-color: $white;
            border: 1px solid $black;
            border-top: 4px solid $black;
            z-index: 5001;
            li {
                border-bottom: 1px solid darken($white, 5%);
            }
            a {
                display: block;
                text-decoration: none;
                padding: 8px 10px;/*IE8*/
                padding: rem(8px) rem(10px);
                &:hover,
                &:focus,
                &:active {
                    background-color: darken($white, 5%);
                }
            }
        }
    }

    #logged_in {
        .account-dropdown {
            width: 15%;
        }
    }

    #login {
        .account-dropdown {
            width: auto;
        }
    }

    #bMenu,
    #cMenu {
        color: darken($white, 30%);
        &:hover {
            color: $black;
        }
    }

    #menu_icon {
        float: left;
        margin: 6px 10px 10px 6px;
        margin: rem(6px) rem(10px) rem(10px) rem(6px);
        position: relative;
        display: none;
    }


    #menu_content {
        padding: 0;
        margin: 0 auto;
        display: block;
        overflow: hidden;
        ul {
            list-style: none;
        }
        .content {
            width: 100%;
            position: absolute;
            z-index: 1002;
            left: 0;
            padding: 1% 3%;
            border: 1px solid $black;
            border-top: 3px solid $black;
            min-height: 280px;/*IE8*/
            min-height: rem(280px);
            li {
                display: block;
            }
            a {
                display: block;
                line-height: 24px;/*IE8*/
                padding: 2px;/*IE8*/
                font-size: 14px;/*IE8*/
                line-height: rem(24px);
                padding: rem(2px);
                font-size: rem(14px);
                text-decoration: none;
            }
        }
        .menu-dropdown {
            @include transition(0.1s, ease-in, all, 0.2s);
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
        }
        .menu:hover .menu-dropdown {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        .navArrow {
            float: left;
            clear: both;
            background-position: center -126px !important;
            margin-top: -8px;
            margin-bottom: -6px;
            height: 8px;
            width: 100%;
            background: transparent url(bg(nav-arrow)) no-repeat scroll;
            display: none;
            position: relative;
            z-index: 70001;
        }
        .shopby:hover .navArrow {
            display: block;
        }

    } // end of #menu_content

    #whatsHot {
        padding-left: 25px;/*IE8*/
        padding-left: rem(25px);
        position: relative;
    }

    //For dynamic menu (added by HY 2017-01-27)
    .menu_thumbnail_column img{
        display: block;
        margin: 0 auto;
    }

    .menu-dropdown{
        .menu_thumbnail_column img{
            display: block;
            margin: 0 auto;
            border: 1px solid #ccc;
        }
        .menu_thumbnail_column~li.menu_link a{
            text-align: center;
            font-size: .85rem!important;
        }
    }


}

// Various media queries

@include media-minmax(1281px, 1420px) {
    #menu .label {
        margin-right: 20px;/*IE8*/
        margin-right: rem(20px);
    }
}

@include media-minmax(801px, 1280px) {
    /*#menu .menu .content .cols.sColor li.swatch {width:50%;}*/
    #menu .cols .imgPre {
        height: 230px!important;/*IE8*/
        height: rem(230px)!important;
        margin: 0!important;
        width: 50%!important;
    }
    #SBColorPanel > .cols:not(.sColor) {
        width: 18%!important;
        margin-right: 2%!important;
    }
    #menu .label {
        margin-right: 15px;/*IE8*/
        margin-right: rem(15px);
        font-size: rem(14px);
        text-transform: capitalize;
    }
}

@include media-minmax(801px, 1000px) {
    #menu .swatch span {
        font-size: 14px!important;/*IE8*/
        font-size: .8rem!important;
    }
    #menu .swatch img {
        width: 20px!important;/*IE8*/
        height: 20px!important;/*IE8*/
        width: rem(20px)!important;
        height: rem(20px)!important;
    }
}

@media only screen and (min-width:1400px) {
    #menu .menu span {
        line-height: 22px;/*IE8*/
        line-height: rem(22px);
    }
    #menu .menu .content li,
    #menu .menu .content li a,
    #menu .menu span,
    #menu .menu .label {
        font-size: 14px;/*IE8*/
        font-size: rem(14px);
    }
}
