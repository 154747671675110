/* **************
	Alerts
**************** */

.top-msg {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 15px 0;
    font-size: 20px;
    transition: all .35s ease-in;
    visibility: hidden;
    opacity: 0;
    border-bottom: 1px solid transparent;
    &.top-msg-success {
        background-color: $teal-dark;
    	color: $white;
    }
    &.top-msg-warning {
		background-color: $warning !important;
		color: darken($danger, 12%);
		border-color: darken($warning, 12%) !important;
    }
    &.revealed {
        visibility: visible;
        opacity: 1;
    }

    .mod-csstransforms & {
        transform: translateY(-300px);
        &.revealed {
            transform: translateY(0);
        }
    }
}

/* **************
	Modals
**************** */

.modal {
	/* Older browsers */
	background-color: $gray;
	background-color: rgba($black, 0.7);

	&--body {
		background-color: $white;
		box-shadow: 0 2px 25px $black;
		padding: 20px;/*IE8*/
		max-width: 1200px;/*IE8*/
		margin: 20px auto;/*IE8*/
		padding: rem(20px);
		max-width: rem(1200px);
		margin: rem(20px) auto;
		border-radius: 2px;
		.heading {
			font-size: 24px;/*IE8*/
			font-size: rem(24px);
			font-weight: $font-weight--bold;
		}
	}

	&--close {
	    right: 10px;
	    top: 5px;
	    color: lighten($black, 40%);
	    font-size: 30px;/*IE8*/
	    line-height: 30px;/*IE8*/
	    height: 30px;/*IE8*/
	    width: 30px;/*IE8*/
	    font-size: rem(30px);
	    line-height: rem(30px);
	    height: rem(30px);
	    width: rem(30px);
	    text-align: center;
	    cursor: pointer;
	    @include transition(0.15s, ease-in-out);
	    &:hover {
	    	background-color: $black;
	    	color: $white;
	    }
	    &:before {
	    	content: "\000D7";
	    }
	}
}
