/* ***************************
    One step checkout cart
****************************** */




#checkout_info {
  padding: 0!important;
}
.customer-checkout {
  margin-top: 2%;
  @include media-desktop {
    padding-left: 15px;
    padding-right: 15px;
  }
  .checkout--headline {
    font-size: 16px;/*IE8*/
    font-size: 2.3rem;
    line-height: 2.3;
    font-weight: 700;
    color: $black;
  }
  .accnt-login {
  	margin-bottom: 15px;/*IE8*/
    margin-bottom: rem(15px);
  }
  .cart-summary .heading {
    font-size: 14px;/*IE8*/
    font-size: .9rem;
    font-weight: normal;
  }
  .cart-summary .cart-grand-total {
    margin-top: 15px;/*IE8*/
    padding-top: 5px;/*IE8*/
    margin-top: rem(15px);
    padding-top: rem(5px);
    border-top: 1px solid $black;
    font-size: 17px;/*IE8*/
    font-size: 1.2rem;
    font-weight: $font-weight--bold;
  }
  .help-text {
      color: darken($white, 30%);
  }
  .is-required:before {
    content: "*";
    display: block;
    position: absolute;
    color: #27986C;
    top: -5px;
    left: -10px;
    font-weight: $font-weight--bold;
    font-size: 17px;/*IE8*/
    font-size: 1.2rem;
  }
  .checkout-cart {
    margin-top: -20px;/*IE8*/
    padding-bottom: 10px;/*IE8*/
    margin-top: rem(-20px);
    padding-bottom: rem(10px);
  }

  /* Promo Code & GC*/
  .promo-input,
  .gc-input {
    max-width: 320px;
  }
  .promo-input {
    padding-bottom: 12px;
    padding-left: 32px;
  }


  /*Layout styling for major elements */

  .payment {
      padding: 0 2% 2% 2%;
      border: 1px solid darken($white, 20%);
  }

  input,
  select {
      border: 1px solid darken($white, 20%);
      border-radius: 2px;
      color: lighten($gray, 10%);
  }
  input:not([type=submit]):focus {
      border-color: $black;
      color: $black;
  }
  label {
      font-size: .8rem;
      font-weight: $font-weight--bold;
      color: #5a5a5a;
      margin-bottom: 3px;/*IE8*/
      margin-bottom: rem(3px);
      display: block;
  }

  label:after {
      content: ":";
  }

  input[type=checkbox] + label,
  input[type=radio] + label {
      display: inline-block;
  }

  input[type=checkbox] + label:after,
  input[type=radio] + label:after{
      content: "";
  }
  .form-group {
    margin-top: 0;
    margin-bottom: 10px;/*IE8*/
    margin-bottom: rem(10px);
  }
  .form-group--inline:after {
    content: "";
    display: table;
    clear: both;
  }

  .form-group--inline div {
      float: left;
      width: 49%;
  }
  .form-group--inline div:last-child {
      margin-left: 2%;
  }
  .is-required {
    position: relative;
  }
  .nosubmit {
      text-transform: none;
      padding-left: 0;
      padding-right: 0;
  }
  .form-ctrl.init-width {
      width: auto;
  }
  .place-order-btn {
      padding: 7px 0;
      width: 100%;
      max-width: 300px;
      text-align: center;
      font-size: 17px;/*IE8*/
      margin: 6px 0;/*IE8*/
      font-size: 1.2rem;
      margin: rem(6px) 0;
      height: 50px;
  }

  .headline {
      position: relative;
      background-color: #555;
      color: #fff;
      font-weight: $font-weight--bold;
      font-size: $base-font-size;/*IE8*/
      font-size: 1.05rem;
      text-transform: uppercase;
      line-height: 1;
      margin: 0 0 20px 0;
      padding: 12px 0 12px 8px;
  }

  .headline:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-top-color: #505050;
      left: 9px;
      margin: auto;
      top: 100%;
      z-index: 10;
  }
  .pm-select {
    position: relative;
    padding-bottom: 5px;
    cursor: pointer;
  }
  .pm-select:after {
    content: '';
    position: absolute;
    display: block;
    top: 2px;
  }
  .pm-select--cc:after {
    width: 125px;
    height: 30px;
    background: url('/meta/pie/0004/0004/0777/icons/cc_images_sprite_v2.png');
    background-size: 125px 30px;
    top: -5px;
    right: 0;
  }
  .pm-select--paypal {
    padding: 18px 0 20px;/*IE8*/
    padding: rem(18px) 0 rem(20px);
    cursor: initial!important;
  }
  .rdo,
  .chk {
    display: none;
  }

  .rdo + label,
  .chk + label {
    height: 26px;
    width: 26px;
    background-size: 26px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .rdo + label:after,
  .chk + label:after {
    display: none;
  }
  .rdo ~ span,
  .chk ~ span {
    font-size: .985rem;
    color: #676767;
    text-transform: uppercase;
    margin-left: 8px;
    vertical-align: top;
  }

  .chk-pm + label {
    background-image: url('/meta/pie/0004/0004/0777/icons/expand_less.png');
  }

  .chk-pm ~ span {
    color: $gray;
  }

  .chk-pm:checked ~ span {
    color: $black;
  }
  .chk-pm:checked + label {
    background-image: url('/meta/pie/0004/0004/0777/icons/expand_more.png');
  }
  .rdo-ship + label {
    background-image: url('/meta/pie/0004/0004/0777/icons/radio_unchecked.png');
  }
  .rdo-ship:checked + label {
    background-image: url('/meta/pie/0004/0004/0777/icons/radio_checked.png');
  }

  .pm {
      background-color: #F5F5F8;
      border-top: 0;
      margin-bottom: 8px;
      padding: 20px 10px 12px;
  }

  .shipping-method-list {
    list-style: none;
    padding: 0;
    margin: -20px 0 0;
  }

  .shipping-method-list .shipping-method-item {
    position: relative;
    padding: 16px 12px 0 7px;
    border-bottom: 1px solid $gray-xtra-light;
    &:last-of-type {
        border-bottom: 0;
    }
  }

  // .shipping-method-list .shipping-method-item:nth-child(odd) {
  //   background-color: #F5F5F8;
  // }

  .shipping-method-list .shipping-method-item:after {
    content : attr(data-ship-price);
    color: green;
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 1em;
    padding: 2px 8px;
    font-weight: $font-weight--bold;
  }

  .shipping-method-list .shipping-method-item span:after {
    content : attr(data-ship-desc);
    color: #777;
    font-size: 14px;/*IE8*/
    font-size: .85rem;
    text-transform: none;
    display: block;
    margin-left: 38px;
    margin-top: -10px;
  }

  /* In Store Pickup List */
  .store-list {
    list-style: none;
    padding: 8px 8px 8px 12px;
    margin: 12px 0 0;
    background-color: #F5F5F8;
    border-radius: 2px;
    position: relative;
    &:before {
      content: "";
      height: 0;
      width: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid darken(#F5F5F8, 5%);

      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      margin: auto;
    }
    li {
      padding: 6px;
      margin-bottom: 7px;
      cursor: pointer;
      position: relative;
      span {
        display: inline-block;
        margin-left: 12px;
      }
      &:before {
        content: "";
        display: inline-block;
        margin-top: 5px;
        width: 16px;
        height: 16px;
        background-image: url("/meta/pie/0004/0004/0777/icons/radio_unchecked.png");
        background-size: 16px 16px;
        vertical-align: top;
      }
      &.selected:before {
        background-image: url("/meta/pie/0004/0004/0777/icons/radio_checked.png");
      }
    }
  }

  .cart-summary .discount:before {
    content: "- $";
  }
  .cart-summary .charges:before {
    content: "+ $";
  }
} // end of .customer-checkout

#acct_registration {
  margin-bottom: 12px;
}
#acct_registration .form-chk + label,
#acct_registration .form-chk ~ span {
  vertical-align: middle!important;
}

#quick_register {
    margin-left: 18px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: .23s ease;
            transition: .23s ease;
}

/*show account register on check */
#is_new_customer:checked ~ #quick_register {
  visibility: visible;
  margin-left: 8px;
  opacity: 1;
}
// .customer-checkout .pm-select--paypal {
//   width: 82px;
//   height: 20px;
//   background: url('/meta/pie/0004/0004/0777/icons/paypal.png');
//   background-size: 82px 20px;
//   right: 15px;
//   text-indent: -9999px;
//   overflow: hidden;
// }
.paypal-btn {
  background: darken($white, 5%);
}
.paypal-btn .paypal-prepos {
  vertical-align: bottom;
}
.paypal-btn .paypal-logo {
  width: 82px;
  height: 20px;
  background: url('/meta/pie/0004/0004/0777/icons/paypal.png');
  background-size: 82px 20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.review-cart-items {
  margin-top: rem(4px);
}

@include media-max(1420px) {
  .customer-checkout {
    padding-left: 1em;
  }
}


@include media-minmax(801px, 1100px) {
  .crt-shipping, .crt-payment {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@include media-mobile {
    .customer-checkout {
      padding-left: 3%;
      padding-right: 3%;
    }

    .customer-checkout .col {
      padding: 0!important;
    }
    .customer-checkout .place-order-btn {
      max-width: 100%;
    }
}

@include media-max(480px) {
    .form-group--inline div {
        float: none!important;
        width: inherit!important;
        margin-top: 8px;
    }
    .form-group--inline div:last-child {
        margin-left: 0!important;
    }
}
