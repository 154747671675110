/* *********************************
              Cart CSS
************************************ */

.cart-header{
	text-transform: uppercase;
	color: #b2b2b2;
	line-height: 1.5;
}

.b-bottom {
	border-bottom: 1px solid #cdcdcd;
}
.padded{
	padding: 10px;/*IE8*/
	padding: rem(10px) 0;
}
.space-below{
	margin-bottom: 5px;/*IE8*/
	margin-bottom: rem(5px);
}

.highlight {
    border-bottom: 3px solid #242527;
}


/* ************
    Wrapper
*************** */

.shopping-cart{
	padding-bottom: 8px;/*IE8*/
	padding-bottom: rem(8px);
	padding-left: 10px;
	padding-right: 10px;
}

.shopping-cart .row {
	margin: 0;
	&.is-spi-embroidery {
		background: url(/meta/pie/0004/0004/0777/textures/dot_texture.gif);
	}
}

/* ************
    Content
*************** */

.shopping-cart-headline{
	padding: 20px 0 5px;/*IE8*/
	padding: rem(20px) 0 rem(5px);
	&:after {
		content: "";
		position: relative;
		top: -5px;
		display: inline-block;
		margin-left: 20px;/*IE8*/
		margin-left: rem(20px);
		height: 18px;
		width: 18px;
		background-size: 18px;
		background-position: center;
	}
}

.discount-message{
	padding-bottom: 24px;/*IE8*/
	padding-bottom: rem(24px);
}

#cart_error {
	padding: 10px;/*IE8*/
    padding: rem(10px);
}

.shopping-cart-contents {
	a{
		color: $gray-lighter;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.cart-thumb-img {
		width: 10%;
		min-width: 80px;
		border: 1px solid transparent;
		overflow: hidden;
		// &:hover {
		// 	// box-shadow: 0 2px 10px rgba($black,.2);
		// 	border-color: $gray-light;
		// }
		img {
			border: 1px solid darken($white, 5%);
			@include transition(0.2s, ease);
			&:hover {
				border-color: $gray-light;
			}
		}
		a {
			display: block;
		}
	}

	.heading{
		font-size: 14px;/*IE8*/
		font-size: rem(14px);
		font-weight: $font-weight--bold;
		line-height: 1.2;
		&,
		a {
			color: $black;
		}
	}
	.heading.brand-name,
	.heading.style-name{
		margin-bottom: 6px;/*IE8*/
		margin-bottom: rem(6px);
	}
	.heading.style-name{
		color: $black !important;
	}
	/* Line Message */
	.add-line-msg {
	  font-size: 14px;
	  text-decoration: underline!important;
	  //color: #6592C6 !important;
	}

	.line-msg {
	  padding: 4px;
	  font-size: 15px;
	  color: #444;
	  font-style: italic;
	  resize: none;
	  overflow: hidden;
	  background-color: #fff;
	  border-radius: 3px;
	    width: 100%;
	    }
	  .line-msg.noedit {
	    border-color: #eee;
	    color: #777;
	    background-color: #f6f6f6;
	    font-style: normal;
	    }
	.save-note-btn,
	.clear-note-btn {
	    display: inline-block;
	    margin-top: 4px;
	    padding: 3px 8px !important;
	    font-size: 14px;
	    font-weight: 400;
	}

	.product_sci { color: darken($white, 30%); }

	.cart-btn{
		margin-bottom: 10px;/*IE8*/
		font-size: 20px;/*IE8*/
		margin-bottom: rem(10px);
		font-size: rem(20px);
	}

	.cart-btn.cart-checkout{
		text-transform: uppercase;
		padding: 12px 0;/*IE8*/
		padding: rem(12px) 0;
		text-align: center;
		width: 100%;
	}


	.continue-shopping{
		text-align: center;
	}

	.continue-shopping a{
		font-size: rem($base-font-size);
		font-weight: bold;
		text-decoration: none;
		color: $black !important;
	}
	.continue-shopping a:before{
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-left: 8px solid transparent;
		border-right: 12px solid $black;
		margin-right: 10px;
	}

	.qty-box{
		position: relative;
	}
	// .qty-box .has-error{
	// 	position: absolute;
	// 	width: 100%;
	// 	display: block;
	// 	left: 0;
	// 	top: -21px;
	// 	text-align: center;
	// 	white-space: pre;
	// }

	.action-controls input,
	.action-controls a {
		text-align: left;
	}


	.action-controls .qty-update{
	    font-size: inherit;
	    background-color: transparent;
	    border: inherit;
	    outline: inherit;
	    cursor: pointer;
	    color: $gray-lighter;
	    width: 100%;
	    font-family: inherit;
	}

	.action-controls{
		margin: -5px 0 0;/*IE8*/
		margin: rem(-5px) 0 0;
	}

	.action-controls li,
	.action-controls a,
	.qty-update{
		display: block;
		padding: 5px;/*IE8*/
		padding: rem(5px);
		line-height: 0.8;
		margin-left: -5px;
	}

	.action-controls a:hover,
	.action-controls .qty-update:hover{
		background-color: $teal;
		color: darken($teal-dark, 10%);
		text-decoration: none;
	}

	.remove-btn {
	    display: block;
	    text-align: center;
	}

	.remove-btn a{
	    display: inline-block;
	    color: #828282!important;
	    font-size: 30px!important;
	    background-color: darken($white, 12%)!important;
	    box-shadow: 0 1px 0px rgba($black, .3);
	}

	.action-controls .remove-on-mobile{ display: none; }

	input[type=text]{
		padding: 2px 4px;
		border: 1px solid;
		border-color: darken($white, 15%);
		@include transition(0.15s, ease);
	}

	input[type=text]:focus,
	input[type=text]:active{
		border-color: $gray-lighter;
		outline: none;
	}

	.embroidery {
		background-color: transparent;
		font-size: 14px;
		border: 0;
	}

	.embroidery-toggle {
		display: block;
		background-color: darken($white, 8%);
	    background-repeat: no-repeat;
	    background-position: 0 5px;
	    background-size: 25px;
	    padding: .5em 25px;
	    opacity: .85;
	    cursor: pointer;
	    &.expand {
		    background-image: url(/meta/pie/0004/0004/0777/icons/expand_less.png);
	    }
	    &.collapse {
		    background-image: url(/meta/pie/0004/0004/0777/icons/expand_more.png);
	    }
	    &:hover {
	    	opacity: 1;
	    }
	}
}
#cart_summary .cart-total {
	margin-bottom: 13px;/*IE8*/
	margin-bottom: rem(13px);
}

@include media-max(1420px) {
	.shopping-cart {
		padding: 0 10px;/*IE8*/
		padding: 0 rem(10px);
	}
}

@include media-minmax(801px, 900px) {
    .shopping-cart-contents .action-controls li,
    .shopping-cart-contents .action-controls a,
    .shopping-cart-contents .qty-update {
    	font-size: 14px!important;/*IE8*/
        font-size: .8rem!important;
    }
    .shopping-cart-contents .qty-update {
    	margin-left: -7px;/*IE8*/
        margin-left: rem(-7px);
    }
}

@include media-mobile {
	.shopping-cart {
		margin-top: 16px;/*IE8*/
		margin-top: rem(16px);
	}
	.shopping-cart-contents .qty-box span.has-error {
        position: relative;
        text-align: left;
        top: 0;
    }
    .shopping-cart-contents {
    	font-size: 14px;/*IE8*/
        font-size: .85rem;
    }
    .shopping-cart-headline {
    	margin-top: 20px;/*IE8*/
    	margin-top: rem(20px);
        padding: 1% 0;
        font-size: 17px;/*IE8*/
        font-size: 1.3rem;
        color: $black;
    }
    .shopping-cart-headline span:after {
        top: 0;
    }
    .cart-header {
        display: none;
    }
    .cart-row {
        position: relative;
    }
    /*	.cart-item-img,
	.cart-item-price,
	.cart-item-actions { display: none; }*/
    .cart-item-img {
        width: 20%!important;
    }
    .cart-item-desc {
        width: 80%!important;
    }
    .cart-item-desc .product_sci {
        display: none!important;
    }
    .cart-item-color,
    .cart-item-size,
    .cart-item-price {
        width: 60%!important;
    }
    .cart-item-color .color-name:before,
    .cart-item-size p:before,
    .cart-item-qty .qty-value:before,
    .cart-item-price .price:before {
        margin-right: 4px;
        display: inline;
        color: darken($white, 30%);
    }
    .shopping-cart-contents .qty-box span.error {
        top: 21px;
        font-size: 13px;/*IE8*/
        font-size: .785rem;
        text-align: left!important;
    }
    .cart-item-qty .qty-value:before {
        content: "Qty:";
    }
    .cart-item-color .color-name:before {
        content: "Color:";
    }
    .cart-item-size p:before {
        content: "Size:";
    }
    .cart-item-size,
    .cart-item-qty {
        text-align: left!important;
    }
    .cart-item-qty {
        width: 40%!important;
    }
    .cart-item-actions,
    .cart-item-total {
        width: 20%!important;
    }
    .cart-item-price {
        display: none!important;
    }
    .cart-item-total {
        float: right;
    }
    .cart-item-actions .qty-update {
        display: none!important;
    }
    .cart-item-actions .action-controls li,
    .cart-item-actions .action-controls li > a {
        display: block!important;
    }
    .cart-item-actions .remove {
        display: none!important;
    }
    .cart-item-actions .remove-on-mobile {
        display: block!important;
        position: absolute;
        top: 0;
        right: 0;
    }
    .cart-item-actions .remove-on-mobile a,
    .cart-item-actions .remove-on-mobile a:hover {
        color: $black!important;
        background: transparent!important;
        font-size: 30px!important;
    }
    #cart_summary > .col {
        float: none!important;
        text-align: left!important;
    }
    #cart_summary .content-box {
        margin-bottom: 2% !important;
    }
    #cart_summary .cart-total {
        margin-right: 0!important;
    }
    #cart_summary .cart-btn {
    	font-size: 16px;/*IE8*/
        font-size: 1.1rem;
        display: block!important;
        width: 100%!important;
        padding-right: 20px!important;/*IE8*/
        padding-left: 20px!important;/*IE8*/
        padding-right: rem(20px)!important;
        padding-left: rem(20px)!important;
    }
}

/*Very small screens*/

@include media-max(370px) {
    .cart-item-img {
        display: none!important;
    }
    .cart-item-color,
    .cart-item-size {
        width: 80%!important;
    }
}
