@mixin font-size($font_size) {
	@if $font_size {
		font-size: $font-size;
		font-size: rem($font-size);
	}
}

@mixin media-mobile {
	@media only screen and (max-width: 800px) {
		@content;
	}
}

@mixin media-desktop {
	@media only screen and (min-width: 801px) {
		@content;
	}
}

@mixin media-minmax($min, $max) {
	@media only screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}

@mixin media-min($min) {
	@media only screen and (min-width: $min) {
		@content;
	}
}

@mixin media-max($max) {
	@media only screen and (max-width: $max) {
		@content;
	}
}

@mixin clearfix {
    content: "";
    display: table;
    clear: both;
}

@mixin disable_user_select {
	user-select: none;
}

@mixin reset_appearance {
    appearance: none;
}

@mixin flex-container ($direction: row) {
	display: flex;
	flex-direction: $direction;
}

@mixin transition($params...) {
	-webkit-transition: $params;
	-moz-transition: $params;
	-ms-transition: $params;
	-o-transition: $params;
	transition: $params;
}

@mixin transform($params...) {
	-webkit-transform: $params;
	-moz-transform: $params;
	-ms-transform: $params;
	-o-transform: $params;
	transform: $params;
}

@mixin animation($animation_props) {
	-webkit-animation: #{$animation_props};
	-moz-animation: #{$animation_props};
	-ms-animation: #{$animation_props};
	animation: #{$animation_props};
}


@mixin keyframes($animation_name) {
	@-webkit-keyframes #{$animation_name} {
		@content;
	}
	@-moz-keyframes #{$animation_name} {
		@content;
	}
	@-ms-keyframes #{$animation_name} {
		@content;
	}
	@keyframes #{$animation_name} {
		@content;
	}
}