/* **************
	Tooltips
**************** */
.has-tooltip {
	position: relative;
	& > .tooltip {
	    position: absolute;
	    z-index: 50;
	    width: 100%;
	    left: 0;
	    bottom: 101%;/*legacy browsers*/
	    bottom: calc(100% + 10px);
	    padding: 8px 10px;
	    color: $white;
	    background-color: $gray;
	    background: rgba($gray,.8);
	    border-radius: 6px;
	    margin-top: 10px;/*IE8*/
	    margin-top: rem(10px);
	    text-align: center;
	    &:after {
		    content: "";
		    width: 10px;/*IE8*/
		    height: 10px;/*IE8*/
		    width: rem(10px);
		    height: rem(10px);
		    border-top: 10px solid $gray;
		    border-top: 10px solid rgba($gray,.8);
		    border-left: 10px solid transparent;
		    border-right: 10px solid transparent;
		    border-bottom: 10px solid transparent;
		    position: absolute;
		    top: 100%; left: 0; right: 0;
		    margin-left: auto;
		    margin-right: auto;
	    }
	}
}
