/* **************
	Buttons
**************** */

.btn {
	text-shadow: 0 1px 0 rgba($black, .014);
	// font-family: $font-stack--btn;
	font-family: 'Titillium Web', tahoma, verdana, sans-serif;
	font-size: 13px;/*IE8*/
	font-size: rem(13px);
	border-radius: 1px;
	padding: 10px 32px;/*IE8*/
	padding: rem(10px) rem(32px);
	border: 1px solid rgba($gray, .11);
	background-color: $gray-xtra-light;
	color: lighten($black, 2%);
	min-height: 32px;
	text-transform: uppercase;
	letter-spacing: 0.021em;
	&--xs {
		padding: 3px 6px;/*IE8*/
		padding: rem(4px) rem(8px);
	}
	&--sm {
		padding: 6px 9px;/*IE8*/
		padding: rem(6px) rem(9px);
	}
	&--lg {
		font-size: 18px;/*IE8*/
		font-size: rem(18px);
	}
	&--block {
		display: block;
		width: 100%;
		text-align: center;
	}
	&--centered {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	&.disabled {
		opacity: .45;
	}

	&:hover {
		text-decoration: none!important;
		//box-shadow: 0 0 3px rgba($gray, .2);
		border-color: rgba($gray, .35);
	}
}

@each $ui-button, $color in $ui-buttons {
	.btn--#{$ui-button} {
		@if ($ui-button == generic) {
			background-color: lighten($gray-xtra-light, 5%)!important;
			color: scale_color($gray, $lightness: 15%)!important;
		}
		@else {
			background-color: $color;
			color: $white!important;
		}
	}
	.btn--#{$ui-button}:hover,
	.btn--#{$ui-button}:active,
	.btn--#{$ui-button}:visited,
	.btn--#{$ui-button}:focus {
		@if ($ui-button == generic) {
			background-color: lighten($gray-xtra-light, 5%)!important;
			color: scale_color($gray, $lightness: 15%)!important;
		}
		@else {
			background-color: darken($color, 3%);
			color: $white!important;
			//box-shadow: 0 0 5px rgba($gray, .35);
		}
	}
}
