/* **************
	Icons
**************** */

#menu_icon {
	&,
	&:after,
	&:before {
		border-color: lighten($gray, 15%);
	}
}

.icon {
	position: absolute;
    text-indent: -99999px;
    background-position: 0 0;
    background-repeat: no-repeat;
    color: $white;
    z-index: 100;
    &--new {
    	//background-size: cover;
    	//background-image: url(bg(new));
	    //display: block;
	    //position: relative;
	    &:after {
		    position: absolute;
		    display: block;
		    content: 'NEW';
		    text-indent: 0;
		    color: #ffffff;
		    width: 40px;
		    height: 40px;
		    padding-top: 9px;
		    background-color: #4db546;
		    border-radius: 50%;
		    transform: rotate(-10deg);
	    }
    }
    &--certainty,
    &--certainty-plus {
        bottom: 5px;
    	width: 40px;
    	height: 40px;
    }
    &--certainty {
    	left: 40px;
    	background-image: url(bg(certainty));
    }
    &--certainty-plus {
    	left: 82px;
    	background-image: url(bg(certainty-plus));
    }
    &--apma {
    	bottom: 5px;
	    background-image: url(bg(apma));
	    background-size: cover;
	    left: 10px;
	    height: 40px;
	    width: 30px;
    }
}
