.group-entry-container {
	.group-code-input {
		text-transform: uppercase;
		padding: 10px 32px;/*IE8*/
		padding: rem(10px) rem(32px);
		@include media-mobile {
			margin-bottom: rem(8px);
		}
	}
}
