.mod-csstransforms.mod-csstransitions {
  .animated-fade-in {
    @include animation(fadeIn .2s ease-in-out);
  }
  
  
  .animated-fade-in-down {
    @include animation(fadeInDown .2s ease-in-out);
  }
  
  .animated-fade-in-left {
    @include animation(fadeInLeft .2s ease-in-out);
  }
  
}

@include keyframes(fadeInDown) {
  0% {
    opacity: 0;
    @include transform(translate3d(0, -100%, 0));
  }
  
  100% {
    opacity: 1;
    @include transform(none);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@include keyframes(fadeInLeft) {
  0% {
    opacity: 0;
    @include transform(translate3d(-100%, 0, 0));
  }
  
  100% {
    opacity: 1;
    @include transform(none);
  }
}

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}