body {
	font-family: $font-stack--main;
}
#header {
	font-family: $font-stack--header;
}
.site-tophat {
	font-family: $font-stack--tophat;
}

.headline {
	font-size: rem(32px);
	line-height: 2;
}

.section-header {
    color: $gray;
    margin: 1% 0px 1% 0px;
    padding: 12px 0 8px;/*IE8*/
    padding: rem(12px) 0 rem(8px);
    text-transform: uppercase;
    font-size: 25px;/*IE8*/
    font-size: 1.5rem;
    text-align: center;
}
