body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	text-shadow: 1px 1px 1px rgba($black, 0.004);
	font-size: $base-font-size;
	overflow-x: hidden; // for menu background.
	overflow-y: auto; // ios safari horizontal scrolling fix.
}


h1, h2, h3 {
	color: $black;
	font-weight: $font-weight--bold;
}

h1 {
	font-size: 24px;/*IE8*/
	font-size: rem(24px);
}
h2 {
	font-size: 21px;/*IE8*/
	font-size: rem(21px);
}
h3 {
	font-size: 18px;/*IE8*/
	font-size: rem(18px);
}

p {
	color: $gray;
}

a {
	&,
	&:focus,
	&:visited,
	&:active,
	&:hover {
		color: $link-color;
	}
}

strong {
	font-weight: bold;
	font-weight: 800;
}

em {
	font-style: italic;
}

.ie9 .placeholder {
	color: #aaa;
}

hr {
	border: 1px solid #ccc;
}
