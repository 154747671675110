.slick-slider {
	margin-bottom: 0;
}

#toast-container > div {
	border-radius: 1px;
	box-shadow: none;
	opacity: .97!important;
}
#toast-container > div:hover {
	box-shadow: none;
}

.toast-success {
	background-color: $success;
}

.toast-error {
	background-color: $danger;
}

.toast-warning {
	background-color: darken($warning, 45%);
}

.toast-info {
	background-color: $primary;
}

#toast-container.toast-bottom-full-width > div,
#toast-container.toast-top-full-width > div {
	width: 100%;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
}