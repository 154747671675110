/* #CATEGORY_HEADER */


/* ******************* */
@include media-desktop {
  #products {
    padding: 0 10px;
  }
}


#category_box .label {
    text-transform: capitalize;
    cursor: pointer;
    font-size: .8rem;
    white-space: nowrap;
}

#category_header {
    text-align: center;
    a,
    a:link,
    a:visited {
        color: $gray-lighter;
    }
}

/*		#category_header .option.selected , #category_header .option.selected a {  background-color:#0fe2b8; color:$gray-xtra-light;}*/


/*Category title*/

#category_title {
    // overflow: hidden;
    .heading {
        line-height: 90px;
        background-color: $gray-xtra-light;
        font-size: 26px;/*IE8*/
        font-size: 2.1rem;
        font-weight: $font-weight--bold;
        text-transform: uppercase;
        margin: 0;
        padding: 20px 0;/*IE8*/
        padding: rem(20px) 0;
        &.no-image {
            background-color: $gray-lighter;
        }
        @include media-mobile {
            font-size: 18px;/*IE8*/
            font-size: rem(18px);
            line-height: 45px;
        }
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
}

/* SORTING AND FILTERING */

#sorting_and_filtering {
    background-color: $white;
    border: 1px solid $gray-xtra-light;
    box-shadow: 0 3px 3px rgba($black, .10);
    padding: 12px;/*IE8*/
    padding: rem(12px);
    margin-top: -5px;
    &.sticky {
        max-width: 1420px;
        width: 100%;
        z-index: 501;
        position: fixed;
        top: 0;
    }
}

.toolbox,
.toolbox .handle,
.filter,
.sort,
.options {
    display: inline-block;
}


/*		.toolbox{ margin-right: 12px; }*/

.toolbox {
    .toolbox .select_group {
        color: $gray-lighter;
    }
    .select_group:hover .options {
        box-shadow: 2px 2px 4px rgba($black, .2);
    }
    .select_group:hover .label:after {
        border-top-color: #903;
    }
    .label:before,
    .label:after {
        border: 4px solid transparent;
        border-top-color: $gray-lighter;
    }
    .option {
        border-bottom: 1px dotted $gray-xtra-light;
    }
    .handle {
        font-weight: $font-weight--bold;
        font-size: 1.1rem;
        letter-spacing: 0.04rem;
        color: $black;
        text-transform: uppercase;
        border-right: 1px solid $gray-xtra-light;
        padding-right: 14px;/*IE8*/
        margin-right: 14px;/*IE8*/
        padding-right: rem(14px);
        margin-right: rem(14px);
    }
}

.filter,
.sort {
    margin: 0 1rem 0;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 0;
    text-align: left;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;

    .mod-no-touch &:hover,
    .mod-no-touch &:hover .options {
        background-color: $white;
    }

    .label {
        padding: 5px;
        color: $gray;
    }

    & > .label:after {
        content: "";
        border-top: 8px solid $gray-lighter;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 4px solid transparent;
        display: inline-block;
        margin-left: 5px;
        top: 5px;
        position: relative;
    }

}

.filter {
    position: relative;
    &.has-selection > .label {
        font-weight: $font-weight--bold;
    }
}

.options {
    top: 100%;
    left: -1px;
    z-index: 201;
    padding: 5px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    overflow: auto;
    max-height: 300px;
    box-shadow: 0 1px 9px rgba(0, 0, 0, .15);
    margin: 0;
    background-color: $white;
    -webkit-transform: translateY(10px);
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    .count,
    .not_available:after {
        color: $gray-lighter;
        margin-left: 5px;
        font-size: 14px;/*IE8*/
        font-size: .8em;
    }

    .count:before {
        content: '(';
    }
    .count:after {
        content: ')';
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.option {
    padding: 5px 3px;
    line-height: 1.3;
    font-size: 14px;/*IE8*/
    font-size: .8rem;
    display: block;
    white-space: nowrap;
    &:first-child {
        margin-top: 7px;
    }
    &.not_available,
    &.not_available:before,
    &.not_available:after {
        border-color: $gray-xtra-light;
        color: $gray-xtra-light;
        padding-left: 0;
    }
    &.not_available:hover {
        background: transparent !important;
    }
    &.not_available:after {
        content: '(0)';
    }
    &.not_available
    &.not_available:before,
    .add:before {
        content: '';
    }
    a {
        text-decoration: none;
    }
}

.filter-options {
    min-width: 160px;
    width: 300%;
    &::-webkit-scrollbar {
       width: 12px;
    }
    &::-webkit-scrollbar-track {
        background-color: $gray-xtra-light;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $gray-light;
    }

    .not_available {
        display: none;
    }

    .option span {
        display: inline-block;
        position: relative;
        top: -3px;
    }
}

#sorting_box {
    position: relative;
}

.sort-options {
    width: 100%;
    left: 0;
    .option {
        padding-top: 3px;
        padding-bottom: 3px;
        a,
        .label {
            display: block;
        }
        &:hover,
        &:hover a,
        &:hover .label,
        &.selected,
        &.selected a,
        &.selected .label {
            background-color: $teal-dark;
            color: $white!important;
        }
    }
}

// For non-touch screens (on mouseover)
.mod-no-touch {
    .filter:hover .filter-options,
    .sort:hover .sort-options {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}
// For touch screens (on touch)
.mod-touch {
    .sort-options.revealed,
    .filter-options.revealed {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

#selected_filters {
    li {
        margin: 0 8px;/*IE8*/
        margin: 0 0.5rem;
        opacity: 0.8;
        overflow: hidden;
        &:hover {
            opacity: 1;
        }
    }
    .remove::before {
        content: "\000D7";
        color: inherit;
        font-weight: bold;
    }
}

.filter {
    label {
        cursor: pointer;
        display: inline-block;
        height: 16px;
        width: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        background-image: url("/meta/pie/0004/0004/0777/icons/checkbox_unchecked.png");
        margin-right: .2em;
    }
    .lbl-checked {
        background-image: url("/meta/pie/0004/0004/0777/icons/checkbox_checked.png");
    }

    .selected span {
        color: #15BDA4;
    }
    input[type="checkbox"] {
        display: none;
    }

    &.active .label {
        font-weight: 800;
        background-color: #690;
    }
    &.collapsed .label:before {
        left: 15px;
        border-top-color: transparent;
        border-left-color: #333;
        border-width: 7px;
        margin-top: -4px;
        margin-left: 0;
    }
}


// #productSearchContainer .filter {
//     padding-left: 0;
// }

// #fabric.filter .not_available {
//     display: none;
// }


/* ********************
Pagination
********************** */

.paging_bar {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    text-align: center;
}

.pagination {
    padding: 15px 0;/*IE8*/
    padding: 1rem 0;
    .previous,
    .next,
    .first,
    .last {
        -webkit-transform: scale(.9);
        -ms-transform: scale(.9);
        transform: scale(.9);
    }
    span {
        display: inline-block;
        margin: 0 5px;
        padding: 8px 15px;
        color: #4b4b4a;
        border-radius: 2px;
    }
    a span {
        border: 1px solid #15BDA4;
        color: #15BDA4;
    }
    a span:hover,
    .active {
        background-color: #15BDA4;
        color: $white;
    }
    .padding {
        color: #4b4b4a !important;
        border: 0 !important;
        background: transparent !important;
    }
}


/* ******************* */


/* #PRODUCTS */


/* ******************* */

@mixin productsPerRow($n) {
    width: (100% / $n);
}

#products {
    overflow: hidden;
    .product_container {
        float: left;
        text-align: center;
        @include media-min(1201px) {
            @include productsPerRow(5);
        }
        @include media-minmax(1001px, 1200px) {
            @include productsPerRow(4);
        }
        @include media-minmax(801px, 1000px) {
            @include productsPerRow(3);
        }
        @include media-mobile {
            @include productsPerRow(2);
            height: 385px;
            min-height: 280px!important;
            font-size: 15px;/*IE8*/
            font-size: .85rem;
            margin-bottom: 0;
            padding-bottom: 14px;/*IE8*/
            padding-bottom: .8rem;
        }
    }
}

@include media-mobile {

    #products {
        margin-bottom: 15px!important;
        .product_container img {
            height: 220px;
        }
        .alternates {
            display: none!important;
        }
    }

    .pagination .padding {
        display: none;
    }
    #filters {
        display: none;
    }
    .product_image {
        height: auto!important;
    }
    .product-desc,
    .product-name {
        max-height: initial!important;
    }
    #products .product_name {
        font-size: inherit;
    }
}

@media only screen and (max-width: 480px) {

    .pagination span {
        padding: 3px 12px!important;
        margin: 0!important;
    }

    #products .product_container {
        height: 292px!important;
        max-height: 309px!important;
    }
    #products .product_container img {
        height: 150px;
    }

    #products .content {
        height: auto!important;
    }
}


@media only screen and (max-width: 420px) {
    #sorting_box .handle {
        float: left;
        margin-right: 2%;
        width: 68%;
    }

    #sorting_box .sort {
        float: right;
        margin-left: auto;
        margin-right: 0;
        width: 30%;

        .sort-options {
            left: auto;
        }
    }
}

#products .content {
    display: block;
    color: #777;
    font-size: 0.75rem;
    text-decoration: none;
    height: 270px;
}

#products .content .legacy {
    max-width: 200px;
    @include media-mobile {
        max-width: 90%;
    }
}

.product_container {
    border: 1px solid transparent;
    padding: 7px 0 20px;/*IE8*/
    margin-bottom: 13px;/*IE8*/
    padding: .5rem 0 2rem;
    margin-bottom: 0.75rem;
    height: 420px;
    text-align: center;
    overflow: hidden;
    position: relative;
    -webkit-transition: .1s linear;
    transition: .1s linear;
}

.product_container:hover {
    border-color: #444;
    border-color: rgba(0, 0, 0, .16);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .12);
}


/*.product_container:hover .alternate-img{
	visibility: visible;
	-ms-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
}*/

.product_container:hover .alternates {
    visibility: visible;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.product_container .product_image {
    position: relative;
    height: 280px;
    overflow: hidden;
}

.product_container .main-img {
    max-height: 260px;
    margin-top: 20px;
}

.product_container .product_image .icon--new {
    //top: 40%;
    left: 40px;
}

.product_container .product_image .brand{
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    text-indent: -9999px;
}

.product_desc {
    line-height: 1.3;
    width: 80%;
    margin: auto;
}

.product_desc .product_name {
    font-weight: bold;
    font-size: 15px;/*IE8*/
    font-size: .95rem;
    margin-bottom: 3px;
}


/*.product_container .alternate-img,*/

.product_container .alternates {
    visibility: hidden;
    -webkit-transition: -webkit-transform .1s cubic-bezier(.09, .45, .66, .89);
    transition: transform .1s cubic-bezier(.09, .45, .66, .89);
}


/* no javascript fallback for lazy load*/

.no-js .lazy {
    display: none;
}

.product_container .alternate-img {
    width: 60px;
    display: block;
    margin: auto;
    position: absolute;
    right: 5px;
    /*		-ms-transform: translateX(300px);
		-webkit-transform: translateX(300px);
		transform: translateX(300px);*/
}

.product_container .alternates {
    padding: 0;
    list-style: none;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    -ms-transform: translateY(300px);
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
}

.product_container .alternates .swatch {
    display: inline-block;
    font-size: 0;
    float: left;
    width: 12%;
    margin: 2px 1%;
    overflow: hidden;
}

.product_container .sci {
    color: #333;
    font-weight: bold;
    text-transform: capitalize;
}

.small_header .toplink {
    border: 1px solid $gray-lighter;
    background-color: $gray-xtra-light;
}

.toplink a {
    color: #666;
}


/* promo */

#promo a {
    color: #666;
}

#promo .highlight {
    color: #903;
    font-weight: bold;
}


/* footer */

#footer_menu {
    background-color: $gray-xtra-light;
    color: $gray-lighter;
}

#footer_menu li a,
#footer_menu li a:visited {
    color: #666;
}

#footer .menu .label {
    border-bottom: 1px dotted #666;
}

#footer .menu:nth-child(1n) .label {
    color: #9e162d;
    border-color: #9e162d;
}

#footer .menu:nth-child(2n) .label {
    color: #306d8b;
    border-color: #306d8b;
}

#footer .menu:nth-child(3n) .label {
    color: #648b18;
    border-color: #648b18;
}

#copyright a:before {
    content: ' | ';
    color: #666;
}


/* content */

.arrow_box:after {
    border: solid transparent;
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #88b7d5;
}

#breadcrumbs a {
    color: $gray-xtra-light;
}

#breadcrumbs .arrow_box:nth-child(1n):after {
    border-left-color: #004D72;
}

#breadcrumbs .arrow_box:nth-child(2n):after {
    border-left-color: #618B11;
}

#breadcrumbs .arrow_box:nth-child(3n):after {
    border-left-color: #9D152D;
}

#breadcrumbs .arrow_box:nth-child(4n):after {
    border-left-color: #DC0058;
}

#breadcrumbs .arrow_box:nth-child(5n):after {
    border-left-color: #692D43;
}

#breadcrumbs .arrow_box:nth-child(6n):after {
    border-left-color: #B4BA0D;
}


/* -------------------------------------------------------  category */

#category_footer .page_box {
    border: 1px dotted $gray-xtra-light;
    border-left: none;
    border-right: none;
    color: #0f587a;
    background-color: $white;
}

#category_footer .label {
    color: #666;
}

#category_footer .current {
    color: #903;
}


/* scroll arrows */

#category_footer .scroll .arrow:after,
#category_footer .scroll .arrow:before {
    border: 8px solid transparent;
}

#category_footer .next .arrow:before {
    border-left-color: $white;
}

#category_footer .next .arrow:after {
    border-left-color: #666;
}

#category_footer .next a.arrow:hover:after {
    border-left-color: #903;
}

#category_footer .previous .arrow:before {
    border-right-color: $white;
}

#category_footer .previous .arrow:after {
    border-right-color: #666;
}

#category_footer .previous a.arrow:hover:after {
    border-right-color: #903;
}

#category_footer .next .not_available:before {
    border-left-color: $white;
}

#category_footer .next .not_available:after {
    border-left-color: #aaa;
}

#category_footer .previous .not_available:before {
    border-right-color: $white;
}

#category_footer .previous .not_available:after {
    border-right-color: #aaa;
}


/* swatches */

#products .alternates .swatch {
    border: 1px solid $gray-xtra-light;
}

#products .alternates .swatch.seemore {
    border-color: $white;
    color: $gray-xtra-light;
}


/* -------------------------------------------------------  product */


/* box shadowed */

.shadowed {
    box-shadow: 0px 24px 12px -25px rgba(1, 1, 1, 0.3);
}


/* scroll arrows */

#product .arrow:before,
#product .arrow:after {
    border: 15px solid transparent;
}

#product .arrow.prev:after {
    border-right-color: $white;
}

#product .arrow.prev:before {
    border-right-color: #000;
}

#product .arrow.next:after {
    border-left-color: $white;
}

#product .arrow.next:before {
    border-left-color: #000;
}

.nav_box {
    background-color: $white;
    border: 1px solid $white;
}

.nav_box:hover {
    border-color: $gray-lighter;
}

.cycle-pager span {
    border: 1px solid $gray-lighter;
    border-radius: 50%;
    background-color: $white;
}

.cycle-pager span.cycle-pager-active {
    background-color: #903;
}

#recommended .heading,
#coordinating_colors .heading {
    color: #447d97;
}

#reviews .heading {
    color: #c25625;
}

#description .js-toggle:after {
    border: 1px dotted $white;
    border-top-color: $gray-xtra-light;
}

#description .js-toggle:hover {
    color: #903;
}

#product .swatch {
    border: 1px solid #aaa;
}

#product .swatch.selected {
    border-color: #000;
}

#reviews .review {
    border-bottom: 1px dotted $gray-xtra-light;
}

.rating > .star.full:before,
.rating > .star.half:after,
.rating > .star.half:before {
    color: gold;
}

.rating > .star:before {
    color: $gray-xtra-light;
}

#recommended .loading {
    color: $gray-lighter;
}

.cycle_box .product_link {
    color: #777;
}

.cycle_box .sci {
    color: #903;
}

.cycle_box .sci {
    border-bottom: 1px solid #903;
}

// Quick look button
.quick-look-container {
    position: relative;
    .quick-look {
        display: none;
        position : absolute;
        width: 150px;
        max-width: 150px;
        height: 35px;
        line-height: 35px;
        bottom: 40%;
        padding: 10px 5px;
        padding: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 4px;
        background-color: darken($white, 5%);
        box-shadow: 0 3px 3px rgba($gray, 0.4);
        z-index: 101;
        @include transition(.2s, ease);
        .mod-opacity & {
            opacity: 0.92;
        }
        .mod-opacity &:hover {
            opacity: 1;
        }
        &:hover {
            background-color: darken($white, 8%);
        }

        @include media-mobile {
            display: none!important;
        }
    }
    .quick-look a {
        display: block;
        height: inherit;
        text-decoration: none;
        color: $gray;
        font-weight: $font-weight--bold;
    }
    &:hover .quick-look {
        display: block;
    }
}

// Improved effect on product image hover
.mod-csstransforms {
    // #products .product_container {
    //     transform-origin: 50% 100%;
    // }
    // #products .product_container:hover {
    //     transform: translateY(-2px);
    // }

    .quick-look-container .quick-look {
        display: block;
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px);
        transition: .2s cubic-bezier(.32,-0.17,.75,1.12);
    }
    .quick-look-container:hover .quick-look {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }
}

/*category page*/
.category_page--sale_msrp {color:Gray; text-decoration:line-through;font-size: 80%;}
.category_page--is_on_sale {color:red; font-weight:bold;}
.product_container {height:450px;}
.available_colors {font-size:85%; color:#333;}
.current_collection {margin: 6px 0px 0px 0px;}
#product_info .product-price.product_page--is_on_sale {color:red;}
#you_save {font-size:.9rem;}
#you_save .price.currency, .product_page--is_on_sale {font-style:none; color:red; font-size:.9rem;}