$shipped: darkgreen;
$pending: darkorange;
$cancelled: darkred;

.da_order_history {
	
	.uhome-content-section {
		
		.order-panel {
			
			.order_shipped {
				color: $shipped;
				background-color: mix(rgba(0,0,0,0.03), opacify($shipped,0.3), 90%);
				
				.order_shipped_alert {
					background-color: mix(rgba(0,0,0,0.03), opacify($shipped,0.3), 90%);
					text-align: center;
					margin-bottom: 10px;
					padding: 10px;
					font-weight: bold;
				}
				
				a, p {
					color: lighten($shipped,10%);
				}
				p.order-number.text-primary {
					color: lighten($shipped,5%) !important;
					font-weight: bold;
				}
			}
			
			.order_pending {
				color: $pending;
				background-color: mix(rgba(0,0,0,0.03), opacify($pending,0.3), 90%);
				
				.order_pending_alert {
					background-color: mix(rgba(0,0,0,0.03), opacify($pending,0.3), 90%);
					text-align: center;
					margin-bottom: 10px;
					padding: 10px;
					font-weight: bold;
				}
				
				a, p {
					color: lighten($pending,10%);
				}
				p.order-number.text-primary {
					color: lighten($pending,5%) !important;
					font-weight: bold;
				}
			}
			
			.order_cancelled {
				color: $cancelled;
				background-color: mix(rgba(0,0,0,0.03), opacify($cancelled,0.3), 90%);
				
				.order_cancelled_alert {
					background-color: mix(rgba(0,0,0,0.03), opacify($cancelled,0.3), 90%);
					text-align: center;
					margin-bottom: 10px;
					padding: 10px;
					font-weight: bold;
				}
				
				a, p {
					color: lighten($cancelled,10%);
				}
				p.order-number.text-primary {
					color: lighten($cancelled,5%) !important;
					font-weight: bold;
				}
			}
			
			.product-row-thumb {
				
				max-height: 90px;
			}
		}
		
	}
}
