/* **************
User home page
**************** */

.profile .title{
    font-weight: 800;
    font-size: 17px;/*IE8*/
    font-size: 1.2rem;
    color: #222;
    margin: 16px 0;/*IE8*/
    margin: 1rem 0;
}
.profile .order-history .order-history-header {
	margin-bottom: 17px;/*IE8*/
	padding-bottom: 7px;/*IE8*/
    margin-bottom: 1.2rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
}
.profile .order-history .order-history-header .head {
    font-weight: 800;
    font-size: 16px;/*IE8*/
    font-size: 1rem;
}
.profile .order-history .order-history-line {
	padding: 17px 0 16px;/*IE8*/
    padding: 1.2rem 0 1rem;
}
.profile .order-history .order-history-line:nth-child(even) {
    background-color: #f7f7f7;
}

@media only screen and (max-width: 800px) {
    .profile .order-history .order-history-header {
        display: none;
    }
    .profile .order-history .order-data,
    .profile .order-history .order-details,
    .profile .order-history .order-again {
        margin-bottom: .9rem;
    }
    .profile .order-history .order-date {
        text-align: left!important;
    }
}

/* **************
Loyalty Rewards
**************** */

.program-signup {
	max-width: 460px;
}

.profile-card {
	margin-bottom: 15px;/*IE8*/
	padding: 24px;/*IE8*/
    margin-bottom: rem(15px);
    padding: rem(24px);
    border: 1px solid $gray-xtra-light;
    box-shadow: 0 2px 2px rgba($black, .1);
    position: relative;
    //min-width: 600px;/*IE8*/
    //min-width: rem(600px);
    @include transition(0.2s, ease-in);
    &.profile-card--simple {
        border: 0;
        box-shadow: none;
        &:focus, &:hover {
            border-color: transparent;
            .profile-card-heading {
                color: $black;
                border-color: $black;
            }
        }
    }
    &:focus,
    &:hover {
	    border-color: $primary;
	    //transform: translateY(2px);
	     .profile-card-heading {
		    color: $primary;
		    border-color: $primary;
	     }
    }

	.profile-card-heading,
	.profile-card-text {
	    text-transform: uppercase;
	    font-weight: $font-weight--bold;
	}
	.profile-card-heading {
	    color: $black;
	    font-size: 22px;/*IE8*/
	    font-size: rem(22px);
	    margin: 0 0 18px;/*IE8*/
	    margin: 0 0 rem(18px);
	    @include transition(0.2s, ease-in);
	}
	.profile-card-text {
		font-size: 19px;/*IE8*/
	    font-size: rem(19px);
	}
	.profile-edit-btn {
		vertical-align: middle;
		margin-left: 10px;
		text-transform: uppercase;
	}
	.reward {
		margin-bottom: 12px;/*IE8*/
		margin-bottom: rem(12px);
	}
	.reward-to,
	.reward-name,
	.reward-start {
		margin: 4px 0 0;/*IE8*/
		margin: rem(4px) 0 0;
	}
	.reward-expire {
		margin-top: 0;
	}

	.profile-edit-block {
		padding: 1em;
		background-color: #fafafa;
		border: 1px solid #eee;
	}
}

.rewards-lifetime {
    margin-left: 2%;
}

@include media-mobile {
	.profile-card {
		float: none;
		min-width: 320px;/*IE8*/
		min-width: rem(320px);
	}
    .rewards-progress {
        padding-right: 0 !important;
    }
    .rewards-lifetime {
        padding-left: 0 !important;
        margin-left: 0 !important;
    }
}

@media only screen and (max-width: 460px) {
	.profile-card {
		.profile-edit-btn {
			margin-left: 0;
		}
	}
}
