/* **************
	Front Page
**************** */

/* Slideshow */

.mod-no-csstransitions .slideshow .slide-arrow {
	opacity: 1!important;
}
.ie8 .slideshow .slide-arrow,
.ie9 .slideshow .slide-arrow {
	display: block;
	z-index: 1001;
}

.slideshow {
    position: relative;
    overflow: hidden;

	/* slideshow in landscape (default) */
	.slideshow--landscape {
	    display: block;
	    width: 100%;
	    max-height: 550px;/*IE8*/
	    max-height: rem(550px);
	    .banner-item {
		    max-width: 100%;
		    position: relative;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		}
	}

	/* slideshow in portrait*/
	.slideshow--portrait {
	    float: left;
	    width: 68%;
	    max-height: 660px;/*IE8*/
	    max-height: rem(660px);
	    img {
	    	width: 100%;
	    	max-width: 100%;
		}
	}

	/* SLIDESHOW CONTROLS */

	.banner-item img {
		&.in-landscape {
			display: block;
		}
		&.in-portrait {
			display: none;
		}
	}

	&:hover .slide-arrow {
	    opacity: 1;
	    @include transition(0.3s, ease-in-out);
	}

	.slide-arrow {
	    position: absolute;
	    top: 45%;
	    font-size: 48px;/*IE8*/
	    font-size: 3rem;
	    cursor: pointer;
	    padding: 0 10px;/*IE8*/
	    padding: 0 rem(10px);
	    opacity: 0;
	    line-height: 40px;/*IE8*/
	    line-height: 3.75rem;
	    height: 48px;/*IE8*/
	    height: 4rem;
	    z-index: 15;
	    background-color: #eee;
	    color: lighten($gray, 42%);
	    box-shadow: 0 2px 1px lighten($gray, 40%);
	    @include transition(0.3s, ease-in-out);
	    @include disable_user_select;
	    .mod-touch & {
	    	opacity: 1!important;
	    }
		&:hover {
		    opacity: 1;
		}
		&.prev {
		    left: 10px;
		}

		&.next {
		    right: 10px;
		}

		&.prev:after {
		    content: "\02039";
		    display: block;
		}

		&.next:after {
		    content: "\0203A";
		    display: block;
		}
	}
}

@include media-mobile {
	.mod-touch .slideshow {
		/* A fix for Android browsers */
		width: 100%;
	}
    .slideshow .banner-item img {
    	&.in-landscape {
    		display: none;
    	}
    	&.in-portrait {
    		display: block;
    	}
    }
    .slideshow--landscape,
    .slideshow--portrait {
    	.slide-arrow {
	        text-shadow: 0 0 10px rgba($black, .5);
	        /* @new Smaller arrows on mobile */
	        top: 30%;
	        padding: 0 6px;/*IE8*/
	        padding: 0 rem(6px);
	        height: 45px;/*IE8*/
	        line-height: 45px;/*IE8*/
	        font-size: 30px;/*IE8*/
	        height: rem(45px);
	        line-height: rem(45px);
	        font-size: rem(30px);
	        opacity: 1!important;
		    &.prev {
	    		left: 5px;
	    	}
	    	&.next {
	    		right: 5px;
	    	}
    	}
    }
}

/* Billboards */

/* Horizontal billboards (3 in a row) */

.billboards--span-3 {
    display: block;
    margin-top: 1%;
    overflow: hidden;
    .billboard-item {
	    float: left;
	    width: 32.66%;
	    margin: 0 .5%;

	    &:first-child {
	    	margin-left: 0;
	    }
	    &:last-child {
	    	margin-right: 0;
	    	clear: right;
	    }
	    img {
	    	width: 100%;
	    }
    }
}


/* Vertical billboards (2) */

.billboards--side-2 {
    float: left;
    margin-left: 1%;
    width: 31%;
    overflow: hidden;
    max-height: 660px;/*IE8*/
    max-height: rem(660px);
    .billboard-item {
	    float: left;
	    margin-bottom: 2%;
	    &:last-child {
	    	margin-bottom: 0;
	    }
	    img {
	    	max-width: 100%;
	    }
    }
}

@include media-mobile {
    .billboards--span-3 .billboard-item,
    .billboards--side-2 .billboard-item {
        font-size: 0;
        width: 100%;
        margin: 0;
    }
    .billboards--span-3 .billboard-item:nth-child(2),
    .billboards--side-2 .billboard-item:last-child {
        margin-right: 0;
    }
    .billboards--span-3 .billboard-item:last-child {
        display: none;
    }
    .billboards .billboard-item {
        display: block;
        width: 100%;
        float: none;
    }
    .billboards .billboard-item img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}



/* Featured Items (Front Page) */

.featured-items {
    width: 100%;
    float: left;
    clear: both;
		background-color: $white;
    position: relative;
	.nav-arrow {
		position: absolute;
	    top: 45%;/*legacy browsers*/
	    top: calc(50% - 1rem);
	    font-size: 45px;/*IE8*/
	    line-height: 45px;/*IE8*/
	    font-size: 3rem;
	    line-height: 4rem;
	    background-color: darken($white, 8%);
	    color: $gray-light;
	    box-shadow: 0 2px 1px $gray-lighter;
	    font-weight: $font-weight--light;
	    padding: 0 5px;/*IE8*/
	    padding: 0 rem(5px);
	    cursor: pointer;
	    opacity: .925;
	    z-index: 15;
	    @include transition(.16s, ease-in);
	    @include disable_user_select;

	    &:hover {
	    	opacity: 1;
	    }
	    &:before,
	    &:after {
	    	display: block;
	    }
	   	&.prev {
	   		left: 10px;
	   		&:after {
	   			content: "\02039";
	   		}
	   	}
	   	&.next {
	   		right: 10px;
	   		&:after {
			    content: "\0203A";
	   		}
	   	}
	}
}

.featured--shop-all {
    color: $gray-lighter;
    text-decoration: underline;
    font-weight: $font-weight--regular;
    font-size: 14px;/*IE8*/
    font-size: .9rem;
    position: absolute;
    right: 0;
    top: 35px;
}

.featured-items-wrapper {
    width: 100%;
    float: left;
    clear: both;
    background-color: $white;
    overflow: hidden;
    height: 390px;/*IE8*/
    height: rem(390px);
}

.featured-item {
    width: 20%;
    float: left;
    clear: none;
    text-align: center;
    padding-top: 0%;
	.featured-item--image {
	    float: left;
	    width: 100%;
	    text-align: center;
	    vertical-align: bottom;
	    overflow: hidden;
	    height: 320px;/*IE8*/
	    height: rem(320px);
	    img {
	    	max-height: 320px;/*IE8*/
	    	max-height: rem(320px);
	    }
	}
	.featured-item--details {
	    float: left;
	    width: 100%;
	    text-align: center;
	    display: block;
	    color: lighten($gray, 12%);
	    margin: auto;
	    line-height: 16px;/*IE8*/
	    margin-bottom: 3px;/*IE8*/
	    line-height: rem(16px);
	    margin-bottom: rem(3px);
	    &.title {
	    	height: 30px;/*IE8*/
		    height: rem(30px);
		    font-weight: $font-weight--bold;
		    margin-top: 8px;/*IE8*/
		    letter-spacing: 1px;/*IE8*/
		    margin-top: rem(8px);
		    letter-spacing: rem(0.5px);
	    }
	}
}

@include media-max(1420px) {
    .featured-items .nav-arrow.prev {
        left: 5px;
    }
    .featured-items .nav-arrow.next {
        right: 5px;
    }
}
@include media-minmax(801px, 1000px) {
    .featured-items {
    	line-height: $base-font-size;/*IE8*/
        line-height: 1rem;
    }
    .featured-item {
    	width: 25%;
    	&.last {
    		display: none;
    	}
    	.featured-item--title {
    		font-size: $base-font-size;/*IE8*/
    		margin-bottom: 18px;/*IE8*/
	        font-size: 1.05rem;
	        margin-bottom: 1.3rem;
    	}
		.featured-item--image {
	        height: 300px;
	    }
    }
}
@include media-mobile {
	.featured-items {
		display: none;
	}
}

/* Ad banners */

.ad-banners {
    width: 100%;
    height: auto;
    float: left;
    clear: both;
		background: $white;
    padding: 1% 0 2%;
    > div {
	    width: 49%;
	    margin-right: 2%;
	    float: left;
	    clear: none;
	    display: table-cell;
	    text-align: center;
	    vertical-align: middle;
	    // max-width:690px;

	    &:last-child {
	    	margin-right: 0;
	    }
    }
    img {
    	width: 100%;
    }
}

@include media-mobile {
    .ad-banners > div {
        float: left;
        width: 100%;
        max-width: 100%;
        clear: both;
        padding: 0;
        margin: 0;
        margin-bottom: 4px;/*IE8*/
        margin-bottom: rem(4px);
    }
}


/* Brand Row / Brandbar */
.brandbar {
    width: 100%;
		padding: 25px 0;/* IE8 */
		padding: rem(25px) 0;
    background-color: $white;
    float: left;
    text-align: center;
}

// Generic class.
.brandbar-item {
    display: inline-block;
    margin: 0 auto;
    width: 10%;
    max-width: 100px;/*IE8*/
    max-width: rem(100px);
    text-align: center;
    height: 50px;/*IE8*/
    height: rem(50px);
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -9999px;

    a {
    	display: block;
    	height: 50px;/*IE8*/
    	height: rem(50px);
    }
}

@include media-mobile {
	.brandbar {
        display: none;
    }
}



/*featured items*/
.featured-item.is_on_sale { position: relative; }
.featured-item.is_on_sale:after { position: absolute;content: 'Sale!';display: block;top: 9px;left: 8px;padding: 11px 5px;border-radius: 50%;transform: rotate(-10deg);font-size: 0.9rem;background-color: lightcoral;color: white;text-align: center;transition: bottom 300ms ease-in-out 500ms;z-index: 2;/*box-shadow: 0 1px 3px rgba(0,0,0,0.2), 0 4px 7px rgba(0,0,0,0.2);*/ }
.featured-item--details.pricerange.is_on_sale { font-size:20px;font-size:1rem;font-weight:800;color: red; }
.featured-item--sale_msrp {color:Gray;  text-decoration:line-through;font-size: 80%; height:1rem;}
.featured-items-wrapper {height:27rem;}
.featured-item .featured-item--details.title {height:2rem; vertical-align:center;}