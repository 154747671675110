$ceiling: 3;

.text-primary {
	color: $primary !important;
}
.text-muted {
	color: lighten($gray, 25%) !important;
}
.text-danger {
	color: $danger !important;
}
.text-success {
	color: $success !important;
}
.text-warning {
	color: $warning !important;
}

.text-small {
	font-size: $base-font-size / 1.18 !important;
}

/* margin y */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.m-y-#{$i} {
			margin-top: #{$i}em!important;/*IE8*/
			margin-bottom: #{$i}em!important;/*IE8*/
			margin-top: #{$i}rem!important;
			margin-bottom: #{$i}rem!important;
		}
	} @else{
		.m-y-0 {
			margin-top: 0!important;
			margin-bottom: 0!important;
		}
	}
}

/* margin x */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.m-x-#{$i} {
			margin-left: #{$i}em!important;/*IE8*/
			margin-right: #{$i}em!important;/*IE8*/
			margin-left: #{$i}rem!important;
			margin-right: #{$i}rem!important;
		}
	} @else{
		.m-x-0 {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}
}

/* padding y */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.p-y-#{$i} {
			padding-top: #{$i}em!important;/*IE8*/
			padding-bottom: #{$i}em!important;/*IE8*/
			padding-top: #{$i}rem!important;
			padding-bottom: #{$i}rem!important;
		}
	} @else{
		.p-y-0 {
			padding-top: 0!important;
			padding-bottom: 0!important;
		}
	}
}

/* padding x */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.p-x-#{$i} {
			padding-left: #{$i}em!important;/*IE8*/
			padding-right: #{$i}em!important;/*IE8*/
			padding-left: #{$i}rem!important;
			padding-right: #{$i}rem!important;
		}
	} @else{
		.p-x-0 {
			padding-left: 0!important;
			padding-right: 0!important;
		}
	}
}

/* margin top */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.m-t-#{$i} {
			margin-top: #{$i}em!important;/*IE8*/
			margin-top: #{$i}rem!important;
		}
	} @else{
		.m-t-0 {
			margin-top: 0!important;
		}
	}
}

/* margin bottom */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.m-b-#{$i} {
			margin-bottom: #{$i}em!important;/*IE8*/
			margin-bottom: #{$i}rem!important;
		}
	} @else{
		.m-b-0 {
			margin-bottom: 0!important;
		}
	}
}

/* margin right */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.m-r-#{$i} {
			margin-right: #{$i}em!important;/*IE8*/
			margin-right: #{$i}rem!important;
		}
	} @else{
		.m-r-0 {
			margin-right: 0!important;
		}
	}
}

/* margin left */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.m-l-#{$i} {
			margin-left: #{$i}em!important;/*IE8*/
			margin-left: #{$i}rem!important;
		}
	} @else{
		.m-l-0 {
			margin-left: 0!important;
		}
	}
}


/* padding top */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.p-t-#{$i} {
			padding-top: #{$i}em!important;/*IE8*/
			padding-top: #{$i}rem!important;
		}
	} @else{
		.p-t-0 {
			padding-top: 0!important;
		}
	}
}
/* padding bottom */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.p-b-#{$i} {
			padding-bottom: #{$i}em!important;/*IE8*/
			padding-bottom: #{$i}rem!important;
		}
	} @else{
		.p-b-0 {
			padding-bottom: 0!important;
		}
	}
}
/* padding right */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.p-r-#{$i} {
			padding-right: #{$i}em!important;/*IE8*/
			padding-right: #{$i}rem!important;
		}
	} @else{
		.p-r-0 {
			padding-right: 0!important;
		}
	}
}
/* padding left */
@for $i from 0 through $ceiling {
	@if $i > 0 {
		.p-l-#{$i} {
			padding-left: #{$i}em!important;/*IE8*/
			padding-left: #{$i}rem!important;
		}
	} @else{
		.p-l-0 {
			padding-left: 0!important;
		}
	}
}

@include media-mobile {
	/* margin y */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.m-sm-y-#{$i} {
				margin-top: #{$i}em!important;/*IE8*/
				margin-bottom: #{$i}em!important;/*IE8*/
				margin-top: #{$i}rem!important;
				margin-bottom: #{$i}rem!important;
			}
		} @else{
			.m-sm-y-0 {
				margin-top: 0!important;
				margin-bottom: 0!important;
			}
		}
	}

	/* margin x */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.m-sm-x-#{$i} {
				margin-left: #{$i}em!important;/*IE8*/
				margin-right: #{$i}em!important;/*IE8*/
				margin-left: #{$i}rem!important;
				margin-right: #{$i}rem!important;
			}
		} @else{
			.m-sm-x-0 {
				margin-left: 0!important;
				margin-right: 0!important;
			}
		}
	}

	/* padding y */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.p-sm-y-#{$i} {
				padding-top: #{$i}em!important;/*IE8*/
				padding-bottom: #{$i}em!important;/*IE8*/
				padding-top: #{$i}rem!important;
				padding-bottom: #{$i}rem!important;
			}
		} @else{
			.p-sm-y-0 {
				padding-top: 0!important;
				padding-bottom: 0!important;
			}
		}
	}

	/* padding x */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.p-sm-x-#{$i} {
				padding-left: #{$i}em!important;/*IE8*/
				padding-right: #{$i}em!important;/*IE8*/
				padding-left: #{$i}rem!important;
				padding-right: #{$i}rem!important;
			}
		} @else{
			.p-sm-x-0 {
				padding-left: 0!important;
				padding-right: 0!important;
			}
		}
	}

	/* margin top */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.m-sm-t-#{$i} {
				margin-top: #{$i}em!important;/*IE8*/
				margin-top: #{$i}rem!important;
			}
		} @else{
			.m-sm-t-0 {
				margin-top: 0!important;
			}
		}
	}

	/* margin bottom */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.m-sm-b-#{$i} {
				margin-bottom: #{$i}em!important;/*IE8*/
				margin-bottom: #{$i}rem!important;
			}
		} @else{
			.m-sm-b-0 {
				margin-bottom: 0!important;
			}
		}
	}

	/* margin right */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.m-sm-r-#{$i} {
				margin-right: #{$i}em!important;/*IE8*/
				margin-right: #{$i}rem!important;
			}
		} @else{
			.m-sm-r-0 {
				margin-right: 0!important;
			}
		}
	}

	/* margin left */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.m-sm-l-#{$i} {
				margin-left: #{$i}em!important;/*IE8*/
				margin-left: #{$i}rem!important;
			}
		} @else{
			.m-sm-l-0 {
				margin-left: 0!important;
			}
		}
	}


	/* padding top */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.p-sm-t-#{$i} {
				padding-top: #{$i}em!important;/*IE8*/
				padding-top: #{$i}rem!important;
			}
		} @else{
			.p-sm-t-0 {
				padding-top: 0!important;
			}
		}
	}
	/* padding bottom */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.p-sm-b-#{$i} {
				padding-bottom: #{$i}em!important;/*IE8*/
				padding-bottom: #{$i}rem!important;
			}
		} @else{
			.p-sm-b-0 {
				padding-bottom: 0!important;
			}
		}
	}
	/* padding right */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.p-sm-r-#{$i} {
				padding-right: #{$i}em!important;/*IE8*/
				padding-right: #{$i}rem!important;
			}
		} @else{
			.p-sm-r-0 {
				padding-right: 0!important;
			}
		}
	}
	/* padding left */
	@for $i from 0 through $ceiling {
		@if $i > 0 {
			.p-sm-l-#{$i} {
				padding-left: #{$i}em!important;/*IE8*/
				padding-left: #{$i}rem!important;
			}
		} @else{
			.p-sm-l-0 {
				padding-left: 0!important;
			}
		}
	}
}
