@font-face {
	font-family: 'Futura Std';
	font-weight: 700;
	src: url("/meta/pie/dist/fonts/Futura Std/Futura Std Book.otf");
}
@font-face {
	font-family: 'Futura Std';
	font-weight: 900;
	src: url("/meta/pie/dist/fonts/Futura Std/Futura Std Heavy.otf");
}

$font-stack--main : 'Futura Std', "Century Gothic", "Helvetica Neue", sans-serif !default;
$font-stack--header : 'Futura Std', "Titillium Web", "Helvetica Neue", sans-serif !default;
$font-stack--btn : arial, sans-serif;
$font-stack--tophat: 'Futura Std', "Tittilium Web", "PT Mono", Consolas, "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--bold: 800;
$base-font-size: 15px;