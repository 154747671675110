/* **************
 Content
**************** */

.site-content {
	background-color: $white;
}

.content-block {
	margin: 16px 0 17px;/*IE8*/
	margin: rem(16px) 0 rem(17px);
}

.info-content {
	margin: 30px auto 12px auto;/*IE8*/
	margin: rem(30px) auto rem(12px) auto;
	width: 100%;
	max-width: 800px;/*IE8*/
	max-width: rem(800px);
	@include media-mobile {
		padding: 0 16px;/*IE8*/
		padding: 0 rem(16px);
	}

	.headline {
		margin-top: 9px;/*IE8*/
		margin-bottom: 25px;/*IE8*/
		margin-top: rem(9px);
		margin-bottom: rem(25px);
	}
	h1,
	h2,
	h3 {
		font-weight: $font-weight--bold;
	}
	h2,
	h3 {
		margin-bottom: 16px;/*IE8*/
		margin-bottom: rem(16px);
	}
	h2 {
		font-size: 21px;/*IE8*/
		font-size: 1.3rem;
	}
	h3 {
		font-size: 19px;/*IE8*/
		font-size: 1.15rem;
		color: $gray;
	}
	ul {
		padding-left: 16px;/*IE8*/
		padding-left: rem(16px);
		list-style-type: square;
	}
	p,
	li {
		color: $gray-lighter;
	}
	p {
		margin-bottom: 10px;/*IE8*/
		margin-bottom: rem(10px);
	}
}
